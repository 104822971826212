@import "../../../../styles/App.scss";

.response {
  @include flexCol;
  background: transparent;
  padding-top: 50px;
&-0{
  min-height: 300px;
}
  &__buttons {
    align-self: center;
    width: 400px;
    @include flexCol;
    align-items: center;
    gap: 20px;
    padding: 40px 0;
    z-index: 3;
    @media screen and (max-width: 768px) {
      width: 80%;
    }
    &__button {
      @include commonButton;
      &--wrong {
        background: linear-gradient(0deg, #ff0000, #ff0000), rgba(255, 255, 255, 0.1);
        &:hover {
          background: #ff0000;
        }
      }
      &--correct {
        background: #00b140;
        box-shadow: 0px 4px 10px rgba(21, 67, 98, 0.5);
        &:hover {
          background: #00b140;
        }
      }
      &__icon {
        position: absolute;
        right: 10px;
      }
    }
  }
  &__copy {
    opacity: 0;
    align-self: center;
    width: 400px;
    height: 400px;
    font-size: 14px;
    line-height: 24px;
    @include respond-to("medium") {
      height: 500px;
  }
    &--correct {
      color: #00b140;
    }
    &--wrong {
      color: #ff0000;
    }
    &-0 {
      height: 30px;
    }
    h4 {
      @extend %font-sans-serif-bold;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 24px;
      @media screen and (max-width: 768px) {
        width: 80%;
        margin: auto;
      }
    }
    p {
      @extend %font-sans-serif-medium;
      font-size: 16px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.8);
      @media screen and (max-width: 768px) {
        width: 80%;
        margin: auto;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
