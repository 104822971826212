@import "../../../styles/App.scss";

.genomes-container {
  position: relative;
  width: toRem(32);

  @include respond-to("medium") {
    width: toRem(50);
  }

  @include respond-to("large") {
    width: toRem(80);
  }
}

.genomes-small {
  height: toRem(100);

  @include respond-to("medium") {
    height: toRem(120);
  }

  @include respond-to("large") {
    height: toRem(200);
  }
}

.genomes-medium {
  height: toRem(150);

  @include respond-to("medium") {
    height: toRem(200);
  }

  @include respond-to("large") {
    height: toRem(400);
  }
}

.genomes-large {
  height: toRem(280);

  @include respond-to("medium") {
    height: toRem(300);
  }

  @include respond-to("large") {
    height: toRem(500);
  }
}

.genomes-xlarge {
  height: toRem(320);

  @include respond-to("medium") {
    height: toRem(400);
  }

  @include respond-to("large") {
    height: toRem(600);
  }
}

.genome {
  background-color: rgba(255, 255, 255, 0.05);
  position: absolute;
  width: 100%;

  @for $i from 0 through 7 {
    &-#{$i} {
      animation: sequenceOne (random(30) + 10)+s linear infinite;
      height: toRem((random(30) + 10));
    }
  }

  @for $i from 8 through 15 {
    &-#{$i} {
      animation: sequenceTwo (random(31) + 10)+s linear infinite;
      height: toRem((random(30) + 10));
    }
  }
}

@keyframes sequenceOne {
  0% {
    top: 0;
    opacity: 0;
  }

  25% {
    opacity: 1;
    top: 50%;
  }

  50% {
    opacity: 0;
    top: 100%;
  }

  75% {
    opacity: 1;
    top: 50%;
  }

  100% {
    top: 0;
    opacity: 0;
  }
}

@keyframes sequenceTwo {
  0% {
    top: 100%;
    opacity: 0;
  }

  25% {
    opacity: 1;
    top: 50%;
  }

  50% {
    opacity: 0;
    top: 0;
  }

  75% {
    opacity: 1;
    top: 50%;
  }

  100% {
    top: 100%;
    opacity: 0;
  }
}