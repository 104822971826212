@import '../../styles/App.scss';

.interactive {
  position: relative;
  width: $width100;
  color: #e1e1e1;
  @include flexCol;
  &::before {
    content: '';
    z-index: -1;
    position: fixed;
    top: 0;
    width: $width100;
    height: 120%;
    transform: translate3d(0, 0, 0);
    background: linear-gradient(0deg, rgba(11, 13, 50, 0.6), rgba(11, 13, 50, 0.6)),
      linear-gradient(102.89deg, #009cde -21.27%, #001c31 41.67%, #001c32 85.21%), #ffffff;
    background-attachment: fixed;
  }
  &__stages {
    position: relative;
    width: $width100;
    @include flexCol;
  }

  &__scrollProgress {
    @include fadeInAnimation;
    position: absolute;
    left: 2rem;
    top: 50vh;
    transform: translate(0%, -50%);
    z-index: 10;
    @media screen and (max-width: 768px) {
      top:30vh;
      left:-5px;
      width: 40px;
    }
  }
}

// button {
//   border: none;
//   background: linear-gradient(72.3deg, #366bb5 6.28%, #22bff8 87.53%), #000000;
//   border-radius: 30px;
//   @extend %font-sans-serif-bold;
//   color: #fff;
//   text-transform: uppercase;
//   font-size: 16px;
//   line-height: 19px;

//   cursor: pointer;
// }
