@import "../../styles/App.scss";

.relatedContent {
  padding: toRem(35) 5% toRem(100);
  overflow-x: scroll;

  @include respond-to("large") {
    padding: toRem(80) 5% toRem(100);
  }

  &--article {
    padding: 0;
  }

  &__heading-container {
    margin-bottom: toRem(30);

    @include respond-to("medium") {
      margin-bottom: toRem(20);
    }

    h3 {
      @extend %font-sans-serif-bold;
      @include fontSize(14, 14);
      text-transform: uppercase;

      @include respond-to("large") {
        @include fontSize(18, 18);
      }
    }
  }

  &-container {
    display: flex;
    gap: toRem(20);
    justify-content: space-between;
    width: fit-content;
    // padding-bottom: toRem(20);
  }
  &__item {
    background-position: center center;
    background-size: cover;
    border-radius: 0 0 toRem(50) 0;
    display: flex;
    // flex: 0 0 toRem(320);
    flex-direction: column;
    width: toRem(400);
    height: toRem(500);
    justify-content: flex-end;
    padding: 0 toRem(40) toRem(35);
    position: relative;
    text-decoration: none;
    color: $white;
    transition: background-size 0.5s linear;
    &:hover {
      background-size: 410px;
    }
    @include respond-to("medium") {
      // flex: 0 1 33.33%;
    }
    &__coming {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 14px;
      @extend %font-sans-serif-light;
    }
    &__eyebrow {
      span {
        @extend %font-sans-serif-bold;
        @include fontSize(20, 24);
        text-transform: uppercase;
      }
    }

    &__title {
      h3 {
        @extend %font-sans-serif-bold;
        @include fontSize(36, 36);
        text-transform: uppercase;
      }
    }

    &::before {
      content: "";
      height: toRem(10);
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &.color-blue1,
    &.color-blue2,
    &.color-blue3 {
      &::before {
        background-color: $colorPrimary;
      }

      .relatedContent__item__eyebrow {
        color: $colorPrimary;
      }
    }


    &.color-orange {
      &::before {
        background-color: $orange;
      }

      .relatedContent__item__eyebrow {
        color: $orange;
      }
    }

    &.color-yellow {
      &::before {
        background-color: $yellow;
      }

      .relatedContent__item__eyebrow {
        color: $yellow;
      }
    }

    &.color-green {
      &::before {
        background-color: $green;
      }
      .relatedContent__item__eyebrow {
        color: $green;
      }
    }

    &.color-purple {
      &::before {
        background-color: $purple;
      }

      .relatedContent__item__eyebrow {
        color: $purple;
      }
    }
  }
}

.qaArticle {
  .relatedContent__heading-container {
    display: none;
  }
}
