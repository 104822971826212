@import "../../../styles/App.scss";

.qaCarousel {
    align-self: center;
    width: 100%;
    margin-top: 10.5vw;
    @include respond-to("medium") {
        margin-top: min(60px, 6vw);
    }
    @include flexCol;
    h3 {
        font-size: 18.0894px;
        line-height: 38px;
        text-align: center;
        letter-spacing: 0.376862px;
        text-transform: uppercase;
        margin: 0;
        @extend %font-sans-serif-semi;
        @include respond-to("medium") {
            font-size: 24px;
            line-height: 50px;
            letter-spacing: 0.5px;
        }
    }
    &__arrows {
        width: min(492px, 100%);
        display: flex;
        z-index: 6;
        align-self: center;
        position: absolute;
        justify-content: space-between;
        @include respond-to("medium") {
            width: min(820px, 100%);
        }
        @include respond-to("large") {
            width: min(1084px, 100%);
        }
        & button {
            padding: 0;
            background: transparent;
            border: none;
            width: 60px;
            height: 60px;
        }
        &__arrow {
            &:hover {
                cursor: pointer;
            }
            &-right {
                transform: rotate(180deg);
            }
        }
    }
    &__container {
        align-self: center;
        position: relative;
        width: 432px;
        height: 340px;
        overflow: hidden;
        padding: 40px 0;
        @include flexRow;
        @include respond-to("medium") {
            width: 760px;
            padding: 52px 0;
            height: 396px;
        }
        @include respond-to("large") {
            width: 1020px;
        }
    }
    &__cards {
        position: absolute;
        z-index: 5;
        left: -108px;
        height: 260px;
        width: 2160px;
        @include flexRow;
        @include respond-to("medium") {
            left: -220px;
            width: 2400px;
            height: 292px;
        }
        @include respond-to("large") {
            left: -210px;
        }
        &__card {
            position: relative;
            width: $smallCardW;
            height: 260px;
            background: #f9f9f9;
            border-radius: 0px 0px 9.19034px 9.19034px;
            color: $text;
            margin: 0 8px;
            -webkit-backface-visibility: hidden; /* Safari */
            -webkit-transform-style: preserve-3d;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            @include flexCol;
            justify-content: flex-start;
            @include respond-to("medium") {
                width: $largeCardW;
                height: 292px;
                border-radius: 0px 0px 10px 10px;
                margin: 0 10px;
            }
            &__bar {
                align-self: center;
                position: absolute;
                top: 0;
                width: 80px;
                height: 8px;
                @include respond-to("medium") {
                    width: 90px;
                    height: 10px;
                }
            }
            &__image {
                align-self: center;
                width: 110px;
                height: 110px;
                margin-top: 30px;
                margin-bottom: 14px;
                @include respond-to("medium") {
                    width: 120px;
                    height: 120px;
                    margin-bottom: 16px;
                    margin-top: 36px;
                }
            }
            &__name {
                align-self: center;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 8px;
                @extend %font-sans-serif-bold;
                @include respond-to("medium") {
                    font-size: 18px;
                    line-height: 21px;
                    margin-bottom: 10px;
                }
            }
            &__title {
                text-align: center;
                align-self: center;
                font-size: 9px;
                line-height: 11px;
                width: 166px;
                @extend %font-sans-serif-light;
                @include respond-to("medium") {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }
    &__indicator {
        align-self: center;
        width: 174px;
        height: 12px;
        @include flexRow;
        justify-content: space-between;
        &__circle {
            fill: #555555;
            &-active {
                fill: $white;
            }
        }
    }
}
