@import '../../../../../styles/App.scss';

.sequence2 {
  align-self: center;
  height: 400vh;
  width: 100%;
  position: relative;
  align-items: center;
  align-self: center;
  @include flexCol;
  justify-content: flex-start;
  &-wrapper {
    width: 100%;
    @include flexCol;
    justify-content: flex-start;
  }
  &__tests {
    position: relative;
    opacity: 0;
    width: 100%;
    z-index: 4;
    @include flexCol;
    justify-content: flex-start;
  }

  &__copy {
    align-self: center;
    margin-top: $common-padding;
    height: 230px;
    @include commonWidth;
    @include intCopyReg;
    @extend .interactive__upperText;
    @include respond-to('small') {
      height: 70px;
    }
    @include respond-to('large') {
      height: 150px;
    }
    &__text {
      @include textAnimationGsap;
      @extend .interactive__upperText__copy;
    }

    &-lower {
      height: fit-content;
      margin-top: 5vh;
      @include respond-to('small') {
        margin-top: -5vh;
      }
    }
  }

  &__animation {
    position: relative;
    width: 80%;
    height: 65vw;
    min-height: 256px;
    align-self: center;
    overflow: visible;
    margin-top: $common-padding-m;
    
    @include respond-to('small') {
      width: 462px;
      height: 226.8px;
      margin-bottom: 5rem;
    }
    @include respond-to('large') {
      width: 860px;
      margin: 80px 0;
      height: 420px;
    }

    &-wrapper {
      width: 100%;
      height: 100%;
      @include flexCol;
      gap: 10px;

      @include respond-to('small') {
        flex-direction: row;
        gap: 30px;
      }
      @include respond-to('large') {
        gap: 60px;
      }
    }
    &--color {
      position: absolute;
      top: 0;
      opacity: 0;
    }
  }

  &__card {
    align-self: center;
    background-color: transparent;
    width: 100%;
    height: 65vw;
    max-width: 400px;
    min-height: 240px;
    perspective: 800px;

    @include respond-to('small') {
      width: 216px;
      height: 226.8px;
    }

    @include respond-to('large') {
      width: 400px;
      height: 420px;
    }

    &__head {
      @extend %font-sans-serif-bold;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #1f88ad;
      font-size: 15px;
      line-height: 15px;
      margin-bottom: 5%;

      @include respond-to('small') {
        font-size: 10px;
        line-height: 10px;
        margin-bottom: 16%;
      }

      @include respond-to('large') {
        margin-bottom: 8%;
        font-size: 20px;
        line-height: 20px;
      }
    }
    &__icon {
      height: 50vw;
      width: auto;
      max-height: 167px;
      margin-bottom: 6%;
      @include respond-to('small') {
        height: 118px;
        margin-bottom: 16%;
      }

      @include respond-to('large') {
        max-height: 258px;
        height: 258px;
        margin-bottom: 6%;
      }
    }

    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      // overflow: hidden;
      text-align: center;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      @include flexRow;
    }
    // &__virus{
    //     align-self: center;
    //     width: 100%;
    //     height: 100%;
    //     @include flexCol;
    // }

    &__contents {
      align-self: center;
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      /* Safari */
      backface-visibility: hidden;
      background: rgba(7, 52, 88, 0.5);
      border-radius: 10px;
      align-items: center;
      @include flexCol;
    

      &__drawer {
        position: absolute;
        width: 100%;
        height: 85%;
        bottom: 0;
        border-radius: 0 0 10px 10px;
        z-index: 20;
        @include flexCol;
        justify-content: flex-end;
        @include respond-to('small') {
          height: 80%;
        }
        // @include respond-to('large') {
        //   height: 80%;
        // }
        &-contents {
          position: relative;
          @include flexCol;
          justify-content: flex-start;
          align-items: center;
          background: #364659;
          height: 15%;
          border-radius: 0 0 10px 10px;
        }
        &__arrow {
          margin-top: 10px;
          width: 23.16px;
          height: 13px;
          &:hover {
            transform: scale(1.1);
            cursor: pointer;
          }
        }
        p {
          opacity: 0;
          @extend %font-sans-serif-medium;
          padding: 16px;
          padding-top: 10px;
          font-size: 12px;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.8);
          // transition: opacity 0.5s linear;
          @include respond-to('small') {
            padding: 10px;
            font-size: 10px;
            line-height: 16px;
          }
          @include respond-to('large') {
            padding: 30px;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
      &-b {
        transform: rotateY(180deg);
      }
    }
  }
}
