@import "../../../styles/App.scss";

.answers {
    box-sizing: border-box;
    width: 86vw;
    max-width: 620px;
    display: flex;
    justify-content: space-between;
    &__image {
        align-self: flex-start;
        width: 16%;
        max-width: 90px;
        @include flexCol;
        @include respond-to("medium") {
            width: 90px;
        }
        &__bar {
            width: 100%;
            height: 8px;
            @include respond-to("medium") {
                height: 14px;
            }
        }
        &__img {
            align-self: center;
            margin-top: 6px;
            width: 85%;
            height: auto;
            max-width: 80px;
            @include respond-to("medium") {
                margin-top: 10px;
                width: 80px;
                height: 80px;
            }
        }
    }
    &__copy {
        width: 83%;
        box-sizing: border-box;
        background-color: $textBox;
        padding: 20px 10px;
        padding-bottom: 8px;
        @include respond-to("medium") {
            padding: 40px 20px;
            padding-bottom: 22px;
        }
        &__name {
            color: $text;
            font-size: 14px;
            line-height: 18px;
            @extend %font-sans-serif-bold;
            @include respond-to("medium") {
                font-size: 21px;
                line-height: 27px;
            }
        }
        &__body {
            color: $text;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 12px;
            @extend %font-serif-regular;
            @include respond-to("medium") {
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 18px;
            }
        }
        &__highlight {
            font-size: 18px;
            line-height: 20px;
            letter-spacing: -0.5px;
            margin-bottom: 12px;
            margin-top: 12px;
            @extend %font-sans-serif-regular;
            @include respond-to("medium") {
                font-size: 32px;
                line-height: 36px;
                margin-bottom: 18px;
                margin-top: 20px;
            }
            &--gavin {
                color: $plum;
            }
            &--alan {
                color: $fuchsia;
            }
            &--pontiano {
                color: $red;
            }
            &--dora {
                color: $orange-qa;
            }
            &--tulio {
                color: $yellow-qa;
            }
            &--melody {
                color: $green;
            }
            &--jorge {
                color: $ltGreen;
            }
            &--mary {
                color: $blue;
            }
        }
    }
}
