@import "../../../styles/App.scss";

.discovery-footer-nav {
  background-color: $white;
  padding: toRem(40) 0;
  color: $black;
  .inner-wrapper {
    @include flexRow;
    @include maxWidth;
    align-items: center;
    height: 100%;
    justify-content: space-between;

    .discovery-footer-nav-menu {
      ul {
        @include flexRow;
        flex-wrap: wrap;
        gap: toRem(35);
        padding: 0;
        list-style-type: none;

        li {
          a {
            @extend %font-sharp-extra;
            @include fontSize(16, 24);
            color: inherit;
            position: relative;
            text-decoration: none;

            &::after {
              background-color: #63d6c9;
              bottom: toRem(-16);
              content: '';
              display: block;
              height: toRem(8);
              left: 0;
              position: absolute;
              transition: all 50ms ease;
              width: 0;
            }

            &:hover::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}