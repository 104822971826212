@import "../../styles/App.scss";

.hub {
  @include flexCol;
  width: 100%;
  min-width: 320px;
  color: $white;
  overflow: hidden;
  position: relative;
}

.outline-button {
  @extend %font-sans-serif-bold;
  @include fontSize(11);
  border: toRem(1) solid $white;
  border-radius: toRem(50);
  color: inherit;
  display: inline-flex;
  line-height: toRem(26);
  padding: toRem(5) toRem(25);
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    background-color: $white;
    color: #002a3a;
    cursor: pointer;
  }

  @include respond-to("medium") {
    @include fontSize(8);
    border-radius: toRem(26);
    line-height: toRem(20);
  }

  @include respond-to("large") {
    @include fontSize(15);
    border-radius: toRem(50);
    line-height: toRem(40);
    padding: toRem(10) toRem(50);
  }
  &:hover .play-icon {
    filter: invert(1);
  }
}

.play-icon {
  align-self: center;
  height: toRem(15);
  width: toRem(15);
  margin-right: toRem(6.5);
  @include respond-to("medium") {
    height: toRem(11);
    width: toRem(11);
  }
  @include respond-to("large") {
    margin-right: toRem(10);
    height:  toRem(20.5);
    width:  toRem(20.5);
  }
}

.animate-content > div {
  opacity: 0;
  transform: translateY(120px);
  transition: opacity 1.5s linear, transform 1s ease;
}


