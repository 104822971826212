@import "../../../../styles/App.scss";

.finish {
  align-items: center;
  position: relative;
  width: 100%;
  &-common {
    @include flexCol;
  }
  &-stages {
    height: 250vh;
    @include flexCol;
    justify-content: flex-start;
    width: 100%;
    &-wrapper {
      width: 100%;
      top: 0;
      @include flexCol;
      justify-content: flex-start;  
    }
    &-1 {
      height: 300vh;
      
    }
    &-3 {
      height: 350vh;
      @include respond-to("small") {
        height: 300vh;
      }
    }
    &-4 {
      height: auto;
    }
  }

  &__stage {
    align-self: center;
    width: fit-content;
    background: rgba(255, 255, 255, 0.1);
    color: $white;
    border-radius: 8.97387px;
    padding: 0 20px;
    font-size: 8px;
    text-transform: uppercase;
    margin-bottom: 8px;
    @extend %font-sans-serif-bold;
    @include respond-to("medium") {
      border-radius: 10px;
      padding: 6.5px 24px;
      margin-bottom: 20px;
    }
    @include respond-to("xlarge") {
      padding: 10px 50px;
      font-size: 16px;
      border-radius: 20px;
    }
  }
  h4 {
    @extend %font-sans-serif-regular;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    text-align: center;
    color: #e1e1e1;
    @include respond-to("large") {
      font-size: 24px;
      line-height: 28px;
    }
    @include respond-to("xlarge") {
      font-size: 30px;
      line-height: 35px;
    }
  }
  &__copy {
    padding: 40px 0;
    @include commonWidth;
    @include respond-to("xlarge") {
      padding: 5vh 0;
    }
    &--r {
      @include intCopyReg;
    }
    &--b {
      @include intCopybold;
    }
  }
  &__confetti{
    position: absolute;
    top:0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    &__canvas {
      width: 100%;
      height: 100%;
    }
  }
}

.finishContent4 {
  align-self: center;
  @include flexCol;
  &__image {
    width: 100%;
    height: auto;
  }
}
