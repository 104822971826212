@import "../../../styles/App.scss";

.SubPgsQuote__container {
  margin: auto;
  max-width: 90%;
  padding: toRem(40) 0;
  text-align: center;

  @include respond-to("medium") {
    max-width: toRem(614);
  }

  @include respond-to("large") {
    max-width: toRem(830);
  }

  .SubPgsQuote__quote {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s linear;
  }

  .SubPgsQuote__img {
    margin: 0 auto toRem(8);
    width: toRem(28);
  }

  .SubPgsQuote__desc {
    padding: 0 0 toRem(12);

    p {
      @extend %font-serif-regular;
      @include fontSize(20, 24);
      color: #B5B1B1;

      @include respond-to("large") {
        @include fontSize(28, 36);
      }
    }
  }

  .SubPgsQuote__author {
    span {
      @extend %font-sans-serif-medium;
      @include fontSize(10, 12);
      text-transform: uppercase;

      @include respond-to("large") {
        @include fontSize(12, 14);
      }
    }
  }
}