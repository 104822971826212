@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/variables.scss';
@import '../../../../../styles/fonts.scss';

.familyTree {
  @include flexCol;
  align-items: center;
  width: 100%;
  height: fit-content;
  &__paragraph {
    @include commonWidth;
    @include flexCol;

    &-upper {
      @include intCopyReg;
      @include textAnimation;
    }

    &-lower {
      h4 {
        @include intHeadText;
        margin-top: 8.5rem;
        @media screen and (max-width: 768px) {
          margin-top: 3rem;
        }
      }
      :nth-child(2) {
        align-self: center;
        @include intCopyInstruction;
        width: 80%;
        text-align: center;
        margin-top: 2.2rem;
        @media screen and (max-width: 768px) {
          margin-top: 1.2rem;
          width: 100%;
        }
      }
    }
  }
  &__body {
    align-self: center;
    width: 100%;
    @include fadeInAnimation;
    @include flexCol;
    align-items: center;
  }
  &__graphic {
    margin-top: 4rem;
    width: 80vw;
    height: 131.95vw;
    max-width: 300px;
    max-height: 494.83px;
    @include respond-to('medium') {
      width: 60vw;
      height: 46.10vw;
      max-width: 830px;
      max-height: 514px;
    }
  }
}
