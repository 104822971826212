@import './fonts.scss';

//colors-------------------------------------
$white: #ffffff;
$black: #000000;
$colorPrimary: #009cde;

$orange: #f64a00;
$green: #00b140;
$purple: #da16df;
$yellow: #ffd100;

$gray: #b5b1b1;

$indicator: #555555;
$lines: #d9d9d9;
$textBox: #f9f9f9;
$text: #333333;
$blueGreen: #021820;
$plum: #470a68;
$fuchsia: #aa0061;
$red: #e4002b;
$orange-qa: #ff6900;
$yellow-qa: #eeb33b;
$ltGreen: #7ccc6c;
$blue: #23a7d7;
$abbottBlue: #009ada;
$wrong:#ff0000;

$interactiveBlue: #22bff8;

//width--------------------------------------
$widthSmall: 84vw;

//breakpoints--------------------------------
$breakpoints: (
  'small': (
    min-width: 481px
  ),
  'medium': (
    min-width: 768px
  ),
  'large': (
    min-width: 1025px
  ),
  'xlarge': (
    min-width: 1440px
  ),
  '2xlarge': (
    min-width: 1920px
  )
) !default;

//carousel ------------------------
$smallCardW: 200px;
$largeCardW: 220px;
$cardLength: 8;

//nova-scotia---------------------------------
$width100: 100%;
$main-width: 100%;
$tile-count: 3;
$tile-width: 263px;
$spacing-large: 50px;
$spacing-small: 30px;
//width for title and body copy
$content-width-desktop: 640px;
$content-width-tablet: 84%;
$content-width-mobile: 84%;

$layout-columns: 12;
$max-width: 1800px;

//interactive
$common-padding: 3.75rem;
$common-padding-m: 2.5rem;
$common-padding-s: 2rem;