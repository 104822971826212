@import "../../../../../styles/App.scss";

.introGlobe {
    width: $width100;
    align-self: center;
    @include flexCol;
    align-items: center;
    min-height: 130vh;
    @include respond-to("medium") {
        min-height: 150vh;
    }
    &__text {
        padding-top: $common-padding;
        @include commonWidth;
        @include textAnimation;
        &--reg {
            @include intCopyReg;
        }
        &--bold {
            @include intCopybold;
        }
    }
    &__video {
        transition: opacity 1s;
        transform: translateY(-7.5%);
        transform: translateX(10%);
        width: 700px;
        height: 729.87px;
        max-width: 1000px;
        max-height: 1042.67px;
        opacity: 0;
        @include respond-to("small") {
            transform: translateX(0);
            align-self: center;
            margin-left: 0;
        }
        @include respond-to("medium") {
            width: 90vw;
            height: 93.84vw;
        }
        @include respond-to("large") {
            transform: translateY(-5%);
            width: 75vw;
            height: 78.20vw;
        }
    }
}
