@import "../../../styles/App.scss";

.subPgsHeroes__container {
  align-self: center;
  width: 387px;
  padding: toRem(52) 30px;
  max-width: 90%;

  @include respond-to("medium") {
    width: 714px;
    padding: toRem(92) 30px;
  }

  @include respond-to("large") {
    margin: auto;
    padding: toRem(80) 30px;
    width: toRem(1070);
    max-width: 95%;
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: toRem(20);

    .swiper-pagination-bullet {
      background-color: $white;
      height: toRem(12);
      width: toRem(12);
    }
  }

  .subPgsHeroes__item {
    border: toRem(1) solid rgba(255, 255, 255, .2);
    border-radius: toRem(5);
    height: auto;
    padding: toRem(20) toRem(24);

    .subPgsHeroes__img {
      margin: 0 auto toRem(16);
      max-width: toRem(160);
    }

    .subPgsHeroes__name {
      padding: 0 0 toRem(4);
      text-align: center;

      h3 {
        @extend %font-sans-serif-bold;
        @include fontSize(20, 24);
      }
    }

    .subPgsHeroes__title {
      padding: 0 0 toRem(16);
      position: relative;
      text-align: center;
      line-height: 0;
      &::after {
        bottom: 0;
        content: '';
        height: toRem(3);
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: toRem(100);
      }
    

      span {
        @extend %font-sans-serif-regular;
        @include fontSize(12, 15);

      }
    }

    .subPgsHeroes__desc {
      padding: toRem(24) 0 0;

      p {
        @extend %font-sans-serif-regular;
        @include fontSize(12, 18);
        color: #B5B1B1;

        @include respond-to("medium") {
          @include fontSize(14, 20);
        }
      }
    }
  }
}

.swiper-button-next {
  transform: rotate(180deg);
  background-image: url("../../../assets/qa/carousel/carousel_arrow.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  margin-right: -10px;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url("../../../assets/qa/carousel/carousel_arrow.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  margin-left: -10px;
}

.swiper-button-prev::after {
  display: none;
}