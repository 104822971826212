@import "../../../styles/App.scss";

.qa {
    align-self: center;
    width: 92%;
    max-width: 832px;
    padding: 20px 0;

    @include flexCol;
    @include respond-to("medium") {
        padding: 40px 0;
    }
    &__answer {
        opacity: 0;
        transform: translateY(50px);
        transition: all 0.5s linear;
        margin-top: 20px;
        @include respond-to("medium") {
            margin-top: 40px;
        
        }
        &--visible {
            opacity: 1;
            transform: translateY(0);
        }
    }
    &__answer:nth-of-type(odd) {
        align-self: flex-end;
    }
}
