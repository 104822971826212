@import "../../../../../styles/App.scss";

.ending {
  position: relative;
  margin-top: 60px;
  @include fadeInAnimation;
  width: $width100;
  height: 100vh;
  @include flexCol;
  justify-content: flex-end;
  @include respond-to("medium") {
    height: 90vw;
  }
  @include respond-to("xlarge") {
    height: 80vw;
    margin-top: 80px;

  }
  position: relative;
  &-contents {
    @include flexCol;
    @include commonWidth;
    @include textAnimation;
    @include respond-to("large") {
      max-width: 663px;
    }
    padding-bottom: 15%;
  }
  &__fullWidthImg {
    position: absolute;
    z-index: -1;
    width: $width100;
    object-fit: cover;
    height: 100%;
    width: 100%;
    @include flexCol;
    // opacity: 0;
    align-items: center;
  }
  &__copy {
    p {
      margin: 2rem 0;
      @include intCopyReg;

    }
    :nth-child(1) {
      @include intCopybold;
    }
    :nth-child(2) {
      margin-bottom: 0;
    }
  }
  &__links {
    margin-top: 2rem;
    color: #5bc2e7;
    &:hover {
      cursor: pointer;
      color: rgb(54, 107, 181);
    }
    a {
      @include intCopybold;
      color: #5bc2e7;
      &:hover {
        cursor: pointer;
        color: rgb(54, 107, 181);
      }
    }
    
  }
  &__button{
    margin-top: 2.5rem;
    @include commonButton;
    width: 100%;
    font-size: 13px;
    @include respond-to("medium") {
     width: fit-content;
     padding: 0 35px;
    
    }
    @include respond-to("large") {
      width: fit-content;
      padding: 0 50px;
      font-size: 16px;
     }
  }

}
