@import "../../../styles/App.scss";

.numbers {
    position: relative;
    width: 37px;
    height: 36px;
    @include flexRow;
    &--body {
        align-self: center;
        margin-top: 20px;
        @include respond-to("medium") {
            margin-top: 40px;
        }
    }

    &__circle {
        align-self: center;
        fill: none;
        width: 37px;
        height: 36px;
        &__circle {
            fill: #009cde;
            &--menu {
                fill: rgba(255, 255, 255, 0.2);
            }
        }
    }
    &__text {
        display: inline-block;
        position: absolute;
        z-index: 2;
        margin-top: 4px;
        align-self: center;
        font-family: "Trade Gothic LT Std Bold Condensed";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
    }
}
