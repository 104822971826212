@import "../../../styles/App.scss";

.qaHero {
    position: relative;
    align-self: center;
    color: $white;
    width: $width100;
    min-width: 320px;
    max-height: 1602px;
    overflow: hidden;
    @include flexCol;
    justify-content: flex-start;
    @include respond-to("small") {
        max-height: 1532px;
    }
    @include respond-to("medium") {
        max-height: 1532px;
    }
    &__background {
        position: absolute;
        top: 0;
        z-index: -1;
        width: $width100;
        @include flexCol;
        &__image {
            position: relative;
            background-image: url("../../../assets/qa/heroM.jpg");
            height: 167vw;
            max-height: 625px;
            @include background;
            @include respond-to("small") {
                background-position: center 40%;
            }
            @include respond-to("medium") {
                height: 100vw;
                max-height: 800px;
                background-image: url("../../../assets/qa/heroD.jpg");
                background-position: center;
            }
        }
        &__gradient {
            position: absolute;
            top: 0;
            &--top {
                width: $width100;
                height: $width100;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 2.98%, rgba(0, 0, 0, 0) 25.78%),
                    linear-gradient(180.25deg, rgba(0, 0, 0, 0) 23.75%, rgba(1, 10, 14, 0.38) 56.47%, #021820 92.24%),
                    linear-gradient(0.21deg, #021820 7.78%, rgba(2, 24, 32, 0) 68.09%),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
                @include respond-to("medium") {
                    background: linear-gradient(0deg, rgba(2, 24, 32, 0.2), rgba(2, 24, 32, 0.2)),
                        linear-gradient(180deg, rgba(0, 0, 0, 0.5) 2.98%, rgba(0, 0, 0, 0) 25.78%),
                        linear-gradient(
                            180.25deg,
                            rgba(0, 0, 0, 0) 23.75%,
                            rgba(1, 10, 14, 0.38) 56.47%,
                            #021820 92.24%
                        );
                }
            }
            &--bottom {
                position: static;
                width: $width100;
                height: 260vw;
                max-height: 977px;
                background: linear-gradient(175.31deg, rgba(2, 24, 32, 0.5) 57.82%, rgba(0, 156, 222, 0.5) 182.39%),
                    #021820;
                @include respond-to("medium") {
                    height: 100vw;
                    max-height: 812px;
                }
            }
        }
    }

    &__content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        &__carousel {
            opacity: 0;
            align-self: center;
            width: $width100;
            transition: opacity 0.7s linear;
            margin-bottom: 40px;
            @include flexRow;
            @include respond-to("medium") {
                margin-bottom: 80px;
            }
        }
        &__head {
            margin-top: 45vw;
            margin-bottom: 29vw;
            align-self: center;
            width: 92%;
            @include flexCol;
            @include respond-to("medium") {
                width: min(832px, 58%);
                margin: min(14vw, 199px) 0;
            }
            h2 {
                opacity: 0;
                transform: translateY(30px);
                align-self: center;
                width: min(620px, 75%);
                margin-top: 15px;
                @include respond-to("medium") {
                    width: 620px;
                    margin-top: 10px;
                }
            }
        }
        &__copy {
            opacity: 0;
            transform: translateY(70px);
            align-self: center;
            width: min(620px, 92%);
            // transition: all 1s linear;
            @include respond-to("medium") {
                width: 620px;
            }
        }
        &__nav {
            width: $width100;
            z-index: 1;
            align-self: center;
            padding: 16px 0 24px 0;
            @include flexCol;
            @include respond-to("medium") {
                flex-direction: row;
                justify-content: center;
                padding: 0;
                height: 120px;
            }
            @include respond-to("large") {
                height: 160px;
            }

            &__links {
                align-self: center;
                @include maxWidth;
                display: flex;
                justify-content: space-between;
                @include respond-to("medium") {
                    position: absolute;
                }
            }
            &__link {
                align-self: center;
                text-decoration: none;
                text-transform: uppercase;
                &:hover {
                    color: $abbottBlue;
                }
                &-right {
                    text-align: right;
                }
            }
            &__logo {
                align-self: center;
                width: 126px;
                margin-top: 20px;
                @include respond-to("medium") {
                    margin-top: 0;
                    width: 172px;
                    position: absolute;
                    z-index: 2;
                }
                @include respond-to("large") {
                    width: 214px;
                }
                 cursor: pointer;
            }
        }
    }
}
.genomes-left-qa,
.genomes-right-qa {
    align-items: center;
    column-gap: toRem(8);
    display: flex;
    position: absolute;
}

.genomes-left-qa {
    left: -5%;
    top: 5%;

    @include respond-to("medium") {
        top: 10%;
        left: 0%;
    }
}

.genomes-right-qa {
    right: -5%;
    top: 20%;

    @include respond-to("medium") {
        right: 0;
        top: 0;
    }
}
