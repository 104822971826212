@import "../../../../../styles/App.scss";

.map4Static{
    align-self: center;
    align-items: center;
    padding-top: $common-padding;
    @include commonWidth;
    @include flexCol;
    height: auto;
    &__text{
        margin-bottom: 60px;
        width: 100%;
        align-self: center;
        @include textAnimationGsap;
        &--reg{
            @include intCopyReg
        }
        &--bold {
            @include intCopybold;
        }
        &-lower{
            margin-top: 60px;
        }
    }
    &__lottie {
        opacity: 0;
        width: 100%;
        height: auto;
    }


}