@import "../../../styles/App.scss";

.qaNav {
    background-color: $colorPrimary;
    color: $white;
    align-self: center;
    width: 100%;
    min-height: 40px;
    position:absolute;
    top:0;
    z-index: 10;
    @extend %font-sans-serif-bold;
    @include flexCol;
    @include respond-to("medium") {
        min-height: 60px;
    }
    &__collapsed {
        opacity: 0;
        text-transform: uppercase;
        align-self: center;
        font-size: 14px;
        align-items: center;
        gap: 10px;
        &:hover {
            color:  #022D3E;
            cursor: pointer;
        }
        &:hover .qaNav__collapsed__down__path {
            fill: #022D3E;
          }
        @include flexRow;
        @include respond-to("medium") {
            font-size: 20px;
            gap: 20px;
         
        }
        &__down {
            align-self: center;
        }
    }
    &__expanded {
        position: relative;
        align-self: center;
        width: 92%;
        height: auto;
        max-width: 832px;
        padding: 40px 0;
        @include respond-to("medium") {
            padding: 60px 0;
        }
        &__head {
            font-size: 18px;
            line-height: 18px;
            @include respond-to("medium") {
                font-size: 24px;
                line-height: 26px;
            }
        }
        &__subHead {
            display: none;
            @extend %font-sans-serif-light;
            @include respond-to("medium") {
                display: inline-block;
                font-size: 16px;
                line-height: 26px;
            }
        }
        &__exit {
            position: absolute;
            top: 30px;
            width: 42px;
            height: 42px;
            right: 0;
            &:hover {
                cursor: pointer;
            }
            &:hover .qaNav__expanded__exit__path {
                fill: #022D3E;
            }
            &:hover .qaNav__expanded__exit__stroke {
                stroke: #022D3E;
            }
            @include respond-to("medium") {
                top:44px;
            }
        }
        &__list {
            // padding: 40px 0 60px 0;
            padding: 0;
            padding-top: 20px;
            @include respond-to("medium") {
                padding-top: 40px;
                padding-bottom: 60px;
            }
            &__items {
                border-bottom: 1px solid rgba(217, 217, 217, 0.2);
                list-style-type: none;
                padding: 6px 0;
                justify-content: flex-start;

                &--link {
                    width:100%;
                    color: inherit;
                    text-decoration: none;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    font-size: 13px;
                    line-height: 15px;

                    &:hover {
                        color: #022D3E;
                    }
                    @include respond-to("medium") {
                        font-size: 16px;
                        line-height: 24px;
                    }
                    &__text{
                        width: 90%;
                    }
                }
            }
        }
    }
}
