@import "../../../../../styles/App.scss";

.introCards {
    align-self: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height:250vh;
    @include flexCol;
    justify-content: flex-start;
    &-wrapper{
        width: 100%;
        height: 100vh;
        @include flexCol;
        justify-content: flex-start;
    }
  &__scroll {
    @include scrollIcon;
  }
    &__textBox {
   
        display: none;
        @include respond-to("medium") {
            opacity: 0;
            display: flex;
            gap:16px;
            @include flexCol;
        }
        @include respond-to("large") {
            gap:30px;
        }
       
        &--m{
            opacity: 0;
            position: absolute;
            align-self: center;
            top: 520px;
            gap:16px;
            @include flexCol;
            @include respond-to("medium") {
              display: none;
              
            }
        }
    }
    &__text {
        align-self: center;
        padding-top: $common-padding;
        @extend %font-sans-serif-bold;
        font-size: 15px;
        line-height: 24px;
        @include respond-to("medium") {
            font-size: 24px;
            line-height: 32px;
        }
        &-head {
            opacity: 0;
            transform: translateY(50px);
        }
    }

 
    &__body {
        position: relative;
        width: 100%;
        @include flexCol;
        @include respond-to("medium") {
            align-self: center;
            max-width: 1130px;
        }
       
    }
}
