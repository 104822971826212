@import "../../../../../styles/App.scss";

.sequence4 {
    align-self: center;
    width: 100%;
    position: relative;
    @include flexCol;
    @include commonWidth;
    @include interactiveComponentSpacing;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
 
    &__copy {
        align-self: center;
        width: 100%;
        height: auto;

        &__text {
            @include textAnimationGsap;
            @include intCopyReg;
        }
        &-lower {
            // margin-top: -10%;
            // @include respond-to("large") {
            //     margin-top: -20%;
            // }
        }
    }
    &__animation {
        opacity: 0;
        align-self: center;
        position: relative;
        align-items: center;
        width: 80vw;
        max-width: 1000px;
        height: 160vw;
        @include flexCol;
        @include respond-to("small") {
            margin: $common-padding 0;
            height: auto;
        }
        @include respond-to("medium") {
            width: 65vw;
        }
     
        // @include respond-to("large") {
        //     margin-top: -20%;
        // }
     
    }
}
