@function moveD($num) {
    @return calc(240px * $num);
}
@function moveM($num) {
    @return 216px * $num;
}


@keyframes fade-in {
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes moveDown {
    100% {
        transform: translateX(0);
    }
}

@keyframes moveTo1 {
    0% {
        transform: translateX(moveM(-1));
       
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes moveTo2 {
    0% {
        transform: translateX(moveM(-2));
       
    }
    100% {
        transform: translateX(moveM(-1));
       
    }
}
@keyframes moveTo3 {
    0% {
        transform: translateX(moveM(-3));
       
    }
    100% {
        transform: translateX(moveM(-2));
       
    }
}
@keyframes moveTo4 {
    0% {
        transform: translateX(moveM(-4));
        
    }
    100% {
        transform: translateX(moveM(-3));
       
    }
}
@keyframes moveTo5 {
    0% {
        transform: translateX(moveM(-5));
        
    }
    100% {
        transform: translateX(moveM(-4));
        
    }
}
@keyframes moveTo6 {
    0% {
        transform: translateX(moveM(-6));
        
    }
    100% {
        transform: translateX(moveM(-5));
       
    }
}

@keyframes moveTo7 {
    0% {
        transform: translateX(moveM(-7));
        
    }
    100% {
        transform: translateX(moveM(-6));
       
    }
}
@keyframes moveTo8 {
    0% {
        transform: translateX(0);
       
    }
    100% {
        transform: translateX(moveM(-7));
     
    }
}

@keyframes moveTo1R {
    0% {
        transform: translateX(moveM(-7));
     
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes moveTo2R {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(moveM(-1));
       
    }
}

@keyframes moveTo3R {
    0% {
        transform: translateX(moveM(-1));
       
    }
    100% {
        transform: translateX(moveM(-2));
       
    }
}

@keyframes moveTo4R {
    0% {
        transform: translateX(moveM(-2));
    }
    100% {
        transform: translateX(moveM(-3));
    }
}

@keyframes moveTo5R {
    0% {
        transform: translateX(moveM(-3));
      
    }
    100% {
        transform: translateX(moveM(-4));
       
    }
}
@keyframes moveTo6R {
    0% {
        transform: translateX(moveM(-4));
       
    }
    100% {
        transform: translateX(moveM(-5));
       
        
    }
}
@keyframes moveTo7R {
    0% {
        transform: translateX(moveM(-5));
     
    }
    100% {
        transform: translateX(moveM(-6));
    }
}
@keyframes moveTo8R {
    0% {
        transform: translateX(moveM(-6));
    }
    100% {
        transform: translateX(moveM(-7));
    }
}




@media screen and (min-width: 768px)  {
    @keyframes moveTo1 {
        0% {
            transform: translateX(moveD(-1));
           
        }
        100% {
            transform: translateX(0);
        }
    }
    @keyframes moveTo2 {
        0% {
            transform: translateX(moveD(-2));
           
        }
        100% {
            transform: translateX(moveD(-1));
           
        }
    }
    @keyframes moveTo3 {
        0% {
            transform: translateX(moveD(-3));
           
        }
        100% {
            transform: translateX(moveD(-2));
           
        }
    }
    @keyframes moveTo4 {
        0% {
            transform: translateX(moveD(-4));
            
        }
        100% {
            transform: translateX(moveD(-3));
           
        }
    }
    @keyframes moveTo5 {
        0% {
            transform: translateX(moveD(-5));
            
        }
        100% {
            transform: translateX(moveD(-4));
            
        }
    }
    @keyframes moveTo6 {
        0% {
            transform: translateX(moveD(-6));
            
        }
        100% {
            transform: translateX(moveD(-5));
           
        }
    }
    
    @keyframes moveTo7 {
        0% {
            transform: translateX(moveD(-7));
            
        }
        100% {
            transform: translateX(moveD(-6));
           
        }
    }
    @keyframes moveTo8 {
        0% {
            transform: translateX(moveD(0));
           
        }
        100% {
            transform: translateX(moveD(-7));
         
        }
    }
    
    @keyframes moveTo1R {
        0% {
            transform: translateX(moveD(-7));
         
        }
        100% {
            transform: translateX(0);
        }
    }
    
    @keyframes moveTo2R {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(moveD(-1));
           
        }
    }
    
    @keyframes moveTo3R {
        0% {
            transform: translateX(moveD(-1));
           
        }
        100% {
            transform: translateX(moveD(-2));
           
        }
    }
    
    @keyframes moveTo4R {
        0% {
            transform: translateX(moveD(-2));
        }
        100% {
            transform: translateX(moveD(-3));
        }
    }
    
    @keyframes moveTo5R {
        0% {
            transform: translateX(moveD(-3));
          
        }
        100% {
            transform: translateX(moveD(-4));
           
        }
    }
    @keyframes moveTo6R {
        0% {
            transform: translateX(moveD(-4));
           
        }
        100% {
            transform: translateX(moveD(-5));
           
            
        }
    }
    @keyframes moveTo7R {
        0% {
            transform: translateX(moveD(-5));
         
        }
        100% {
            transform: translateX(moveD(-6));
        }
    }
    @keyframes moveTo8R {
        0% {
            transform: translateX(moveD(-6));
        }
        100% {
            transform: translateX(moveD(-7));
        }
    }
}


@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
@keyframes pulse {
  100% {
      transform: scale(1.2);
  }
}
@keyframes sideToSide {
    3% {
        transform: rotate(8deg);

    }
    6%{
        transform: rotate(0);

    }
   9% {
        transform: rotate(-8deg);
    }
    12%{
        transform: rotate(0);
    }
    15% {
        transform: rotate(8deg);

    }
    18%{
        transform: rotate(0);

    }
    100% {
        transform: rotate(0);
    }
  }