@import "../../../styles/App.scss";

.mobileMenu {
    @include layout;
    width: 100%;
    min-width: 320px;
    position: fixed;
    top: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.1);
    transform: translateX(max(100%, 320px));
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: blur(40px);
        backdrop-filter: blur(40px);
    }
    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: rgba(0, 0, 0, 0.8);
    }
    @include respond-to("xlarge") {
        display: none;
    }

    &__contents {
        @include flexCol;
        width: 100%;
        padding-top: 10%;
        height: 100vh;
        box-sizing: border-box;
        position: relative;
        align-self: center;
        justify-content: space-evenly;

        & ul {
            @include flexCol;
            align-self: center;
            width: $widthSmall;
            padding: 0;
        }

        &__close {
            @include flexRow;
            margin: toRem(12) toRem(16);
            z-index: 3;
            border: none;
            background-color: transparent;
            position: absolute;
            box-sizing: border-box;
            top: 0;
            right: 0;

            &:hover {
                cursor: pointer;
            }

            &-img {
                align-self: center;
                width: toRem(42);
                height: toRem(42);
            }
        }

        &__item {
            @include flexCol;
            justify-content: flex-start;
            // row-gap: toRem(40);
            padding: 20px;
            &-container {
                @include flexCol;
                align-self: center;
            }
            &__episode {
                align-self: center;
                @extend %font-sans-serif-light;
                font-size: 12px;
                line-height: 14px;
            }
            &__link {
                @extend %font-sans-serif-medium;
                @include flexRow;
                @include fontSize(18, 21);
                align-self: center;
                color: $white;
                text-decoration: none;
                text-transform: uppercase;
            }
        }

        &__logo {
            align-self: center;
            width: auto;
            height: toRem(40);

            &-img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
