@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.mutationsCheckpt {
  @include interactiveComponentSpacing;
  width: $width100;
  &__paragraph {
    @include commonWidth;
    @include flexCol;
    align-items: center;
    &-upper {
      @include intCopyReg;
      @include textAnimation;
    }

    &-lower {
      h4 {
        @include intHeadText;
        padding-top: 8.5rem;
      }
      :nth-child(2) {
        align-self: center;
        @include intCopyInstruction;
        width: 80%;
        text-align: center;
        margin-top: 2.2rem;
        @media screen and (max-width: 768px) {
          margin-top: 1.2rem;
          width: 100%;
        }
      }
    }
  }
  &__body {
    width: $width100;
    align-self: center;
    @include fadeInAnimation;
    @include flexCol;
    align-items: center;
  }
  &__graphic {
    margin-top: 4rem;
    max-width: 301px;
    max-height: 419px;
    width: 80vw;
    height: 113.62vw;
    @include respond-to("medium") {
      width: 60vw;
     height: 21.37vw;
      max-width: 830px;
      max-height: 295.84px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
