@import "../../../styles/App.scss";

.teaser {
  position: relative;
  margin-top: -2px;
  @include respond-to("medium") {
    height: 56.25vw;
    margin-top: 0;
  }
  &__buttons {
    gap:12px;
    width: fit-content;
    @include flexCol;
    @include respond-to("medium") {
      gap:8px;
     flex-direction: row;
    }
    @include respond-to("large") {
      gap:16px;
    }
  }
  &__eyebrow {
    @extend .eyebrow;
    color: $colorPrimary;
  }
}

.teaser-loop {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
  &-m{
    @include respond-to("medium") {
      display: none;
    }
    
  }
  &-d{
    display: none;
    @include respond-to("medium") {
      display: block;
    }
    
  }
}

.teaser-wrapper {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  height: 100%;

  @include respond-to("medium") {
    @include background;
    background: linear-gradient(52.27deg, rgba(0, 0, 0, 0.6) 23.66%, rgba(0, 0, 0, 0) 73.88%);
    height: 56.25vw;
  }

  .inner-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    @include respond-to("medium") {
      @include maxWidth;
    }

    .teaser-content {
      background: linear-gradient(174.35deg, #021820 40.72%, #009cde 204.94%), #021820;
      @include tileContent;

      .teaser__title {
        h2 {
          @include tileTitleBig;
        }
      }

      .teaser__description {
        p {
          @include tileCopyBig;
        }
      }
    }
  }
}

.film__close {
  position: absolute;
  z-index: 3;

  &:hover {
    cursor: pointer;
  }

  top: calc(50% - 28.125vw - 30px);
  right: 10px;
  width: 25px;
  height: 25px;

  @include respond-to("medium") {
    align-self: center;
    top: calc(50% - 28.125vw + 5px);
    width: min(5vw, 42px);
    height: min(5vw, 42px);
  }

  @include respond-to("large") {
    top: toRem(20);
    right: toRem(20);
  }
}

.film__teaserModal {
  @include flexRow;
  align-self: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  top: 0;
  visibility: hidden;

  @include respond-to("medium") {
    height: 56.25vw;
  }

  .teaser-video {
    align-self: center;
    width: 100%;
    height: 56.25vw;
  }
}
