@import "../../../../styles/App.scss";

.infoButton {
    @include flexRow;
    align-items: center;
    background: #009cde;
    outline: none;
    border: none;
    &:hover {
        cursor: pointer;
        background: linear-gradient(72.3deg, rgba(54, 107, 181, 0.4) 6.28%, rgba(34, 191, 248, 0.4) 87.53%);
    }

    align-self: flex-start;
    gap: 7.46px;
    border-radius: 3.73193px;
    width: 67.17px;
    height: 29.86px;

    @include respond-to("large") {
        gap: 10px;
        width: 90px;
        height: 40px;
        border-radius: 5px;
    }

    &__icon {
        @include flexRow;
        position: relative;
        margin: 0;
        width: 13.45px;
        height: 13.45px;

        @include respond-to("large") {
            width: 18.03px;
            height: 18.03px;
        }
    }
    &__text {
        @extend %font-sans-serif-bold;
        font-size: 8px;
        line-height: 8px;
        text-align: center;
        color: #ffffff;

        @include respond-to("large") {
            font-size: 12px;
            line-height: 24px;
        }
    }
}
