@import "../../../styles/App.scss";

.video {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @include respond-to("medium") {
    height: 56.25vw;
  }

  &::after {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    transition: all 0.5s ease;
    top: 0;
    width: 100%;
  }

  &::before {
    content: "";
    height: 20%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &__map {
    position: absolute;
    opacity: 0;
    transition: opacity 0.7s linear 0.5s;
    z-index: 1;
    left: 0;
    top: 0;
    width: 87%;
    height: auto;
    @include respond-to("medium") {
      width: 42%;
    }
  }

  &.color-orange {
    &::after {
      background-color: $orange;
    }

    &::before {
      background: linear-gradient(180deg, rgba($orange, 0.2) -86.5%, rgba(246, 74, 0, 0) 67.31%);
    }

    .video-eyebrow {
      span {
        color: $orange;
      }
    }

    &.animate-cover::after {
      height: toRem(4);
    }
  }

  &.color-green {
    &::after {
      background-color: $green;
    }

    &::before {
      background: linear-gradient(180deg, rgba($green, 0.2) -86.5%, rgba(246, 74, 0, 0) 67.31%);
    }

    .video-eyebrow {
      span {
        color: $green;
      }
    }

    &.animate-cover::after {
      height: toRem(4);
    }
  }

  &.color-purple {
    &::after {
      background-color: $purple;
    }

    &::before {
      background: linear-gradient(180deg, rgba($purple, 0.2) -86.5%, rgba(246, 74, 0, 0) 67.31%);
    }

    .video-eyebrow {
      span {
        color: $purple;
      }
    }

    &.animate-cover::after {
      height: toRem(4);
    }
  }

  &.color-yellow {
    &::after {
      background-color: $yellow;
    }

    &::before {
      background: linear-gradient(180deg, rgba($yellow, 0.2) -86.5%, rgba(246, 74, 0, 0) 67.31%);
    }

    .video-eyebrow {
      span {
        color: $yellow;
      }
    }

    &.animate-cover::after {
      height: toRem(4);
    }
  }
}

.video-loop {
  object-position: 50% 0;
  position: absolute;
  z-index: -1;
  width: 100%;

  @include respond-to("medium") {
    height: 100%;
    object-fit: cover;
  }

  &.video-m {
    @include respond-to("medium") {
      display: none;
    }
  }

  &.video-d {
    display: none;

    @include respond-to("medium") {
      display: block;
    }
  }
}

.video-wrapper {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  height: 100%;
  position: relative;

  @include respond-to("medium") {
    @include background;
    background: linear-gradient(52.27deg, rgba(0, 0, 0, 0.6) 23.66%, rgba(0, 0, 0, 0) 73.88%);
  }

  &::before {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100%;
    content: "";
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s ease 1s;
    left: 0;
    width: 40vw;
  }

  &.animate-map::before {
    opacity: 1;
  }

  .inner-wrapper {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    @include respond-to("medium") {
      @include maxWidth;
    }

    .video-content {
      background: linear-gradient(174.35deg, #021820 40.72%, #009cde 204.94%), #021820;
      @include tileContent;

      .video-eyebrow {
        span {
          @extend .eyebrow;
        }
      }

      .video-title {
        h2 {
          @include tileTitleBig;
        }
      }

      .video-description {
        p {
          @include tileCopyBig;
        }
      }
    }
  }
}
