@import "../../../../styles/App.scss";

.cards {
   align-self: flex-start;
   width: 100%;

   position: relative;
   @include respond-to("medium") {
      align-self: center;
   }
&__contents{
   width: 100%;
   @include flexRow;
   flex-wrap: wrap;
   @include respond-to("medium") {
      flex-wrap: nowrap;
   }
   &-intro {
      gap: 3%;
      margin-bottom: 50px;
      z-index: 4;
      @include respond-to("medium") {
         gap: 30px;
         align-self: center;
      }
   }
   &-stage {
      gap: 2%;
      margin-bottom: 40px;
      @include respond-to("medium") {
         gap: 10px;
         margin-bottom: 60px;
      }
   }

   &-blank {
      position: absolute;
      top:0;
    }
}
 
 
}
