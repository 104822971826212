@import "../../../../styles/App.scss";

.intro {
    align-self: center;
    position: relative;
    width: $width100;
    height: fit-content;
    @include flexCol;
    &__map {
        opacity: 0;
        transition: opacity 0.7s;
        @include commonWidth;
        align-self: center;
        z-index: 4;
        @include flexCol;
        justify-content: flex-start;
        align-items: center;
        min-height: 60vw;
        @include respond-to("medium") {
            margin-bottom: $common-padding;
        }
        @include respond-to("xlarge") {
            min-height: 100vh;
        }
        &__lottie {
           width: 100%;
            padding: $common-padding-s 0;
            @include respond-to("large") {
                padding: $common-padding-m 0;
                max-width: 610px;
            }
        }

        &__text {
            align-self: center;
            margin-top: $common-padding;
            margin-bottom: $common-padding-m;
            @include textAnimation;
          
            &--reg {
                @include intCopyReg;
           
            }
            &--bold {
                @include intCopybold;
            }
        }
    }
}
