@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/variables.scss';

.antigenCheckpt {
  @include flexCol;
  @include fadeInAnimation;
  @include respond-to('large') {
    @media (max-height: 850px) {
      margin-top: 20rem;
    }
  }

  width: $width100;
  align-items: center;
  &__paragraph {
    @include commonWidth;
    @include flexCol;
    align-items: center;
    :nth-child(1) {
      @include intHeadText;
    }
    :nth-child(2) {
      @include intCopyInstruction;
      text-align: center;
      margin-top: 2.2rem;
      @media screen and (max-width: 768px) {
        margin-top: 1.2rem;
      }
      @include respond-to('medium') {
        white-space: nowrap;
        width: 80%;
      }
    }
  }
  &__graphic {
    margin-top: 4rem;
    width: 80vw;
    height: 80.08vw;
    max-width: 300px;
    max-height: 303px;
    position: relative;
    @include respond-to('medium') {
      max-width: 830px;
      max-height:484.57px;
      width:60vw;
      height: 35.03vw;
    }
    &__img {
      width: 100%;
      height: 100%;
    }
    .infoButtonCont {
      position: absolute;
      right: 1.5rem;
    }
  }
  .infoPanel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;

    &__wrapper {
      align-items: flex-start;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 90vh;
      width: 90vw;
      background-color: #fff;
      border-radius: 25px;
      @media screen and (max-width: 768px) {
        height: 75vh;
        overflow-y: scroll;
      }
    }
    &__xButton {
      position: absolute;
      right: 1.5rem;
      top: -1.5rem;
      z-index: 11;
      @media screen and (max-width: 768px) {
        right: 1.5rem;
        top: 11%;
      }
      button {
        background: none;
        border: none;
        outline: none;
      }
      img {
        width: 40px;
      }
    }
    &__content {
      padding: 3rem;
      padding-top: 0rem;
      @media screen and (max-width: 768px) {
        padding: 1.5rem;
      }
      .infoBlock {
        @include flexCol;
        align-items: flex-start;
        margin-top: 2rem;
        &__header {
          @include flexRow;
          align-items: center;
          :nth-child(2) {
            margin-left: 0.5rem;
            color: #071332;
            @include intCopybold;
            font-size: 18px;
            line-height: 22px;
          }
        }
        &__paragraph {
          color: #071332;
          margin-top: 0.5rem;
          @include intCopyReg;
        }
      }
    }
  }
}
