@import "../../../styles/App.scss";

.inline {
    align-self: center;
    overflow: hidden;
    width: 100%;
    height: 70vw;
    position: relative;
    @include respond-to("medium") {
        max-height: 540px;
    }
    @include respond-to("large") {
        height: 34.75vw;
        max-height: 500px;
    }
    @include flexRow;
  
    &__background {
        position: absolute;
        align-self: center;
        width: 110%;
        height: 110%;
        @include background;
    }

    //replace urls
    &--photo0 {
        background-image: url("../../../assets/qa/fb1M.jpg");
        @include respond-to("medium") {
            background-image: url("../../../assets/qa/fb1.jpg");
        }
    }
    &--photo1 {
        background-image: url("../../../assets/qa/fb2M.jpg");
        @include respond-to("medium") {
            background-image: url("../../../assets/qa/fb2.jpg");
        }
    }
    &--photo2 {
        background-image: url("../../../assets/qa/fb3M.jpg");
        @include respond-to("medium") {
            background-image: url("../../../assets/qa/fb3.jpg");
        }
    }
    &--photo3 {
        background-image: url("../../../assets/qa/fb4M.jpg");
        @include respond-to("medium") {
            background-image: url("../../../assets/qa/fb4.jpg");
        }
    }
    &--photo4 {
        background-image: url("../../../assets/qa/fb5M.jpg");
        @include respond-to("medium") {
            background-image: url("../../../assets/qa/fb5.jpg");
        }
    }
}
