@import "../../../styles/App.scss";

.footer {
  background: linear-gradient(175.31deg, #021820 35.22%, #009CDE 182.39%), #021820;
  overflow: hidden;
  position: relative;

  .inner-wrapper {
    .footer-content {
      @include flexCol;
      @include maxWidth;
      align-items: center;
      flex-wrap: wrap;
      padding: toRem(60) 5%;

      @include respond-to("medium") {
        padding: toRem(140) cols(3);
      }

      .footer-image {
        padding-bottom: toRem(26);
        width: toRem(209);

        @include respond-to("medium") {
          padding-bottom: toRem(21);
          width: toRem(150);
        }

        @include respond-to("large") {
          padding-bottom: toRem(33);
          width: toRem(168);
        }
      }

      .footer-description {
        p {
          @extend %font-sans-serif-regular;
          @include fontSize(16, 24);
          color: $gray;
          padding-bottom: toRem(35);
          text-align: center;

          @include respond-to("medium") {
            @include fontSize(11, 18);
            padding-bottom: toRem(21);
          }

          @include respond-to("large") {
            @include fontSize(16, 24);
            padding-bottom: toRem(33);
          }
        }
      }
    }
  }

  .genomes-left,
  .genomes-right {
    align-items: center;
    column-gap: toRem(8);
    display: flex;
    position: absolute;
  }

  .genomes-left {
    bottom: -30%;
    left: -12%;

    @include respond-to("medium") {
      bottom: 0;
      left: 5%;
    }
  }

  .genomes-right {
    right: -10%;
    top: -40%;

    @include respond-to("medium") {
      right: 5%;
      top: 0;
    }
  }
}