@import "../../../styles/App.scss";

.SubPgsImageGrid__container {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: toRem(20);
  margin: auto;
  max-width: 90%;
  padding: toRem(40) 0;
  width: toRem(1040);

  @include respond-to("medium") {
    gap: toRem(30);
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @include respond-to("large") {
    gap: toRem(40);
  
  }

  .SubPgsImageGrid__item {
    @include respond-to("medium") {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        
      }
      grid-column-start: 4;
      grid-column-end: 6;
   
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
