@import "./variables.scss";

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flexRow {
  display: flex;
  justify-content: center;
}

@mixin background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin layout {
  width: 100%;
  height: auto;
  align-self: center;
  @include flexCol;
}

@mixin commonWidth {
  align-self: center;
  width: 80%;
  max-width: 80%;
  @include respond-to("medium") {
    max-width: 462px;
  }
  @include respond-to("large") {
    max-width: 610px;
  }
}

@function toRem($px) {
  @return calc(($px / 16) * 1rem);
}

@function cols($num, $total: $layout-columns) {
  @return calc(($num / $total) * 100%);
}

@mixin maxWidth($width: $max-width) {
  max-width: $width;
  margin: 0 auto;
  width: 90%;
}

@mixin fontSize($fontsize, $leading: null) {
  font-size: calc(($fontsize / 16) * 1rem);
  @if ($fontsize and $leading) {
    line-height: ceil(($leading / $fontsize) * 10000) / 10000;
  }
}

@mixin tileTitleBig {
  @extend %font-sans-serif-bold;
  @include fontSize(30, 36);
  padding-bottom: toRem(15);
  text-transform: uppercase;

  @include respond-to("medium") {
    @include fontSize(29, 30);
    max-width: 530px;
  }

  @include respond-to("large") {
    @include fontSize(48, 50);
    padding-bottom: toRem(20);
  }
}

@mixin tileTitleSmall {
  @extend %font-sans-serif-bold;
  @include fontSize(28, 32);
  padding-bottom: toRem(8);
  text-transform: uppercase;

  @include respond-to("medium") {
    @include fontSize(24, 26);
  }

  @include respond-to("large") {
    @include fontSize(44, 46);
    padding-bottom: toRem(10);
  }
}

@mixin tileCopyBig {
  @extend %font-serif-regular;
  @include fontSize(16, 24);
  padding-bottom: toRem(20);

  @include respond-to("medium") {
    @include fontSize(12, 16);
  }

  @include respond-to("large") {
    @include fontSize(18, 24);
    padding-bottom: toRem(40);
  }
}

@mixin tileCopySmall {
  @extend %font-sans-serif-light;
  @include fontSize(16, 24);
  padding-bottom: toRem(20);

  @include respond-to("medium") {
    @include fontSize(12, 16);
  }

  @include respond-to("large") {
    @include fontSize(16, 24);
    padding-bottom: toRem(40);
  }
}

@mixin tileContent {
  margin-top: 100vw;
  padding: toRem(60) 5%;

  @include respond-to("medium") {
    background: transparent;
    border-radius: 0;
    flex: 0 0 toRem(274);
    margin-top: 0;
    padding: toRem(80) 0;
  }
  @include respond-to("large") {
    flex: 0 0 toRem(514);
  }
}

@mixin intCopyReg {
  @extend %font-sans-serif-regular;
  @include fontSize(15, 22);
  @include respond-to("large") {
    @include fontSize(20, 30);
  }
}

@mixin intCopybold {
  @extend %font-sans-serif-bold;
  @include fontSize(15, 22);
  @include respond-to("large") {
    @include fontSize(20, 30);
  }
}
@mixin intCopyInstruction {
  @extend %font-sans-serif-regular;
  @include fontSize(11, 13);
  text-align: center;
  @include respond-to("large") {
    @include fontSize(16, 24);
  }
}

@mixin textAnimation {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.7s linear;
}

@mixin textAnimationGsap {
  opacity: 0;
  transform: translateY(50px);
}

@mixin interactiveComponentSpacing {
  margin-top: 7.5rem;
  @include flexCol;
  align-items: center;
  @include respond-to("medium") {
    margin-top: 20rem;
  }
}
@mixin intSpacingBottom {
  margin-bottom: 7.5rem;
  @include respond-to("large") {
    margin-bottom: 20rem;
  }
}
@mixin scrollIcon {
  width: 20.85px;
  height: 11.7px;
  align-self: center;
  @include respond-to("medium") {
    width: 41px;
    height: 23.02px;
  }
}

@mixin popupButton {
  @extend %font-sans-serif-bold;
  text-decoration: underline;
  color: #5bc2e7;
  transition: color 0.5 linear;
  &:hover {
    cursor: pointer;
    color: rgb(54, 107, 181);
  }
  &:focus {
    cursor: pointer;
    color: rgb(54, 107, 181);
  }
}

@mixin fadeInAnimation {
  opacity: 0;
  transition: opacity 0.7s linear;
}

@mixin intHeadText {
  @extend %font-sans-serif-semi;
  text-align: center;
  color: #e1e1e1;
  font-size: 18px;
  line-height: 24px;
  @include respond-to("large") {
    font-size: 36px;
    line-height: 48px;
  }
}

@mixin commonButton {
  @extend %font-sans-serif-bold;
  @include flexRow;
  align-items: center;
  position: relative;
  color: $white;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  background: linear-gradient(72.3deg, #366bb5 6.28%, #22bff8 87.53%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 4px 10px rgba(21, 67, 98, 0.5);
  border-radius: 30px;
  &:hover {
    cursor: pointer;
    background: linear-gradient(
      72.3deg,
      rgba(54, 107, 181, 0.4) 6.28%,
      rgba(34, 191, 248, 0.4) 87.53%
    );
  }
}
