@import "../../../styles/App.scss";

.SubPgsContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  max-width: 90%;
  width: toRem(1040);

  @include respond-to("medium") {
    max-width: 60%;
  }

  @include respond-to("large") {
    flex-direction: row;
    max-width: 90%;
  }

  &.SubPgsContent-bio {
    @include respond-to("large") {
      column-gap: toRem(40);
    }
  }

  .SubPgsContent-container {
    color: #fff;
    padding: toRem(40) 0;
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s ease;
    @include respond-to("large") {
      flex: 0 0 toRem(420);
      padding: toRem(120) 0;
    }

    &.SubPgsContent-bio-container {
      @include respond-to("large") {
        padding-top: 0;
        flex-basis: toRem(450);
      }
    }

    .SubPgsContent-eyebrow {
      span {
        @extend %font-sans-serif-bold;
        @include fontSize(14, 16);
        text-transform: uppercase;
        @include respond-to("large") {
          @include fontSize(24, 50);
        }
      }
    }

    .SubPgsContent-title {
      h2 {
        @extend %font-sans-serif-bold;
        @include fontSize(30, 36);
        text-transform: uppercase;
        margin-bottom: toRem(12);

        @include respond-to("large") {
          @include fontSize(48, 50);
        }
      }

      h3 {
        @extend %font-sans-serif-bold;
        @include fontSize(30, 34);

        @include respond-to("medium") {
          @include fontSize(30, 36);
        }

        @include respond-to("large") {
          @include fontSize(40, 44);
        }
      }
    }

    .SubPgsContent-bio-description {
      position: relative;
      padding-bottom: toRem(20);
      margin: toRem(8) 0 0;
      span {
        @extend %font-sans-serif-regular;
        @include fontSize(12, 15);
        display: block;
      }
      &::after {
        bottom: 0;
        content: "";
        height: toRem(3);
        left: 0;
        position: absolute;
        width: toRem(100);
      }
    }

    .SubPgsContent-description {
      p {
        @extend %font-serif-regular;
        @include fontSize(16, 24);

        @include respond-to("large") {
          @include fontSize(20, 30);
          margin-bottom: toRem(60);
       
        }
      }
    }

    .SubPgsContent-blurb-description {
      p {
        @extend %font-sans-serif-regular;
        @include fontSize(14, 20);
        color: #b5b1b1;
        margin-bottom: toRem(60);
        margin-top:  toRem(20);
      }
    }

    .back-link {
      a {
        @extend %font-sans-serif-regular;
        @include fontSize(16, 18);
        color: $colorPrimary;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}
