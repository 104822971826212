@import "../../../styles/App.scss";

.articles-carousel {
  background: linear-gradient(210.89deg, #021820 63.81%, #009cde 155.17%);
  padding: toRem(30) 0 toRem(50) toRem(28);
  @include respond-to("medium") {
    padding: toRem(40) 0 toRem(40) toRem(40);
  }

  @include respond-to("large") {
    padding: toRem(40) 0 toRem(80) toRem(100);
  }
  .articles-carousel-container {
    display: flex;
    gap: toRem(10);
    overflow-x: auto;
    padding-bottom: toRem(30);
    padding-right: toRem(30);

    @include respond-to("medium") {
      gap: toRem(16);
    }

    @include respond-to("large") {
      gap: toRem(30);
    }

    .article-item {
      height: auto;
      flex: 0 0 toRem(316);
      position: relative;

      @include respond-to("medium") {
        flex: 0 0 toRem(279);
      }

      @include respond-to("large") {
        flex: 0 0 toRem(500);
      }

      &:first-child {
        .article-item-image {
          position: relative;

          &::after {
            bottom: 0;
            content: "";
            height: toRem(16);
            left: 0;
            position: absolute;
            width: toRem(175);
            z-index: 1;
            @include respond-to("medium") {
              height: toRem(9);
              width: toRem(97);
            }

            @include respond-to("large") {
              height: toRem(16);
              width: toRem(175);
            }
          }
        }

        .scroll-next {
          @extend %font-sans-serif-regular;
          @include fontSize(16, 16);
          align-items: center;
          color: $colorPrimary;
          bottom: 0;
          display: flex;
          gap: toRem(5);
          left: 0;
          position: absolute;
          text-transform: uppercase;
          right: auto;
          width: auto;

          @include respond-to("medium") {
            @include fontSize(10, 12);
          }

          @include respond-to("large") {
            @include fontSize(14, 17);
          }
        }
      }

      &:not(:first-child) {
        .scroll-next {
          display: none;
        }
      }
    }

    &.articles-green {
      .article-item {
        &:first-child {
          .article-item-image::after {
            background-color: $green;
          }
        }
      }
    }

    &.articles-orange {
      .article-item {
        &:first-child {
          .article-item-image::after {
            background-color: $orange;
          }
        }
      }
    }

    &.articles-yellow {
      .article-item {
        &:first-child {
          .article-item-image::after {
            background-color: $yellow;
          }
        }
      }
    }

    &.articles-purple {
      .article-item {
        &:first-child {
          .article-item-image::after {
            background-color: $purple;
          }
        }
      }
    }

    .article-item-image {
      img {
        height: toRem(316);
        object-fit: cover;
        object-position: center bottom;
        width: 100%;

        @include respond-to("medium") {
          height: toRem(155);
          object-position: center;
        }

        @include respond-to("large") {
          height: toRem(280);
        }
      }
      &--right {
        img {
          object-position: 80% bottom;
          @include respond-to("medium") {
            object-position: center;
          }
        }
      }
      &--left {
        img {
          object-position: 30% bottom;
          @include respond-to("medium") {
            object-position: center;
          }
        }
      }
      &--left30 {
        img {
          object-position:40% bottom;
          @include respond-to("medium") {
            object-position: center;
          }
        }
      }
    }

    .article-item-title {
      margin-top: toRem(16);

      @include respond-to("medium") {
        margin-top: toRem(20);
      }
      @include respond-to("large") {
        margin-top: toRem(30);
      }

      h3 {
        @extend %font-sans-serif-bold;
        @include fontSize(14, 16);
        color: $white;
        margin-bottom: toRem(10);
        margin-right: toRem(50);

        // @include respond-to("medium") {
        //   margin-bottom: toRem(8);
        // }

        @include respond-to("large") {
          @include fontSize(20, 24);
          margin-bottom: toRem(16);
        }
      }
      &-first {
        h3 {
          @include fontSize(18, 20);

          @include respond-to("medium") {
            @include fontSize(18, 20);
          }

          @include respond-to("large") {
            @include fontSize(32, 36);
          }
        }
      }
    }

    .text-container {
      @extend %font-sans-serif-regular;
      @include fontSize(11, 16);
      margin-right: toRem(50);

      @include respond-to("medium") {
        @include fontSize(11, 15);
      }

      @include respond-to("large") {
        @include fontSize(16, 24);
      }
    }

    .swiper-button-next {
      display: none;
    }
  }

  .subPgsCarousel & {
    background: transparent;
  }
}

.unitedStates .articles-carousel-container .article-item:nth-child(4) .article-item-image img {
  object-position: 0;
}