@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/variables.scss';

.paragraphWLottie {
  @include fadeInAnimation;
  @include interactiveComponentSpacing;
  @include commonWidth;
  @include intSpacingBottom;
  &__textCont {
    p {
      @include intCopyReg;
      margin-top: 2rem;
    }
    :nth-child(7) {
      @include intCopybold;
    }
  }
  &__lottieCont {
    width: 100%;
    margin-top: 5rem;
    // max-width: 508px;
    @media screen and (max-width: 768px) {
      // width: %;
      margin-top: 4rem;
    }
  }
}
