@import "../../../../styles/App.scss";

.stageHero {
    @include flexCol;
    width: 100%;
    scroll-snap-align: start;
    align-items: center;
    position: absolute;
    top: 0;
    opacity: 0;
    transform: scale(0.5);
    padding: 80px 0;
    height: 100vh;
    text-align: center;
    z-index: 10;
    @include respond-to("medium") {
        max-height: 62.5vw;
    }
    &--0 {
        background: url("../../../../assets/interactive/stage1/stage1_bg.png");
        background-size: cover;
    }
    &--1 {
        background: url("../../../../assets/interactive/stage2/stage2_bg.jpg");
        background-size: cover;
    }
    &--2 {
        background: url("../../../../assets/interactive/stage3/stage3_bg.jpeg");
        background-size: cover;
    }
    &--3 {
        background: url("../../../../assets/interactive/stage4/stage4_bg.jpeg");
        background-size: cover;
    }
    &__icon {
        width: 203px;
        height: auto;
        @include respond-to("xlarge") {
            width: 300px;
        }
    }
    &__stage {
        font-size: 8px;
        line-height: 9px;
        text-transform: uppercase;
        background: rgba(255, 255, 255, 0.1);
        padding: 5px 25px;
        border-radius: 12px;
        margin: 40px 0 10px 0;
        @extend %font-sans-serif-bold;
        @include respond-to("medium") {
            margin: 32px 0 6px 0;
        }
        @include respond-to("xlarge") {
            padding: 10px 50px;
            font-size: 16px;
            line-height: 19px;
            border-radius: 20px;
            margin: 60px 0 12px 0;
        }
    }
    h3 {
        @extend %font-sans-serif-book;
        font-size: 48px;
        line-height: 48px;
        @include respond-to("xlarge") {
            font-size: 86px;
            line-height: 92px;
        }
    }
    p {
        @extend %font-sans-serif-medium;
        max-width: 612px;
        width: 80vw;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
        @include respond-to("medium") {
            width: 45vw;
        }
        @include respond-to("xlarge") {
            margin-top: 20px;
            font-size: 20px;
            line-height: 30px;
        }
    }
}
