@import '../../../../../styles/App.scss';

.sequence1 {
  align-self: center;
  height: 260vh;
  width: 100%;
  position: relative;
  @include flexCol;
  justify-content: flex-start;
  align-self: center;
  // z-index: 20;
  &-wrapper {
    width: 100%;
    height: 100vh;
    @include flexCol;
    justify-content: flex-start;
  }
  &__png {
    opacity: 0;
    width: 100%;
    z-index: 4;
    @include flexCol;
  }
  &__copy {
    align-self: flex-start;
    margin-top: $common-padding;
    height: 250px;
    @include commonWidth;

    @include respond-to('small') {
      height: 198px;
    }
    @include respond-to('large') {
      height: 270px;
    }
    @include intCopyReg;
    @extend .interactive__upperText;
    &__text {
      @include textAnimationGsap;
      @extend .interactive__upperText__copy;
      &__popup {
        @include popupButton;
        z-index: 20;
      }
    }
  }
  &__animation {
    opacity: 0;
    align-self: center;
    overflow: visible;
    margin: 2.5rem 0;
    width: 100vw;
    height: 81.68vw;
    @include flexRow;
    @include respond-to('small') {
      width: auto;
      height: calc(100vh - 298px);
      max-width: 462px;
      max-height: 377.36px;
    }
    @include respond-to('large') {
      max-width: 610px;
      max-height: 498.24px;
      height: calc(100vh - 370px);
    }

    &__canvas {
      align-self: center;
      width: 100%;
      height: 100%;
    }
  }
  &__popout1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);

    &__wrapper {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 42%;
      max-width: 610px;
      background-color: #fff;
      border-radius: 25px;
      position: absolute;
      @media screen and (max-width: 1024px) {
        width: 90%;
      }
      .xButton {
        button {
          background: none;
          border: none;
          outline: none;
          position: absolute;
          right: 1rem;
          top: -2rem;
          width: 78px;
          cursor: pointer;
        }
      }
      .textCont {
        @include intCopyReg;
        color: #071332;
        padding: 65px 45px;
        @media screen and (max-width: 768px) {
          padding: 50px 30px;
        }
        img {
          width: 100px;
        }
        :nth-child(1),
        :nth-child(3),
        :nth-child(4) {
          margin-bottom: 2rem;
        }
        :nth-child(2) {
          margin-bottom: 3rem;
        }
      }
    }

    .contentCont {
      @include flexCol;
      padding: 65px 45px;
      align-items: flex-start;
      :nth-child(2) {
        margin-top: 2rem;
        @media screen and (max-width: 1024px) {
          margin-top: 1rem;
        }
      }
      &__imgText {
        img {
          width: 100px;
        }
        p {
          @include intCopyReg;
          color: #071332;
          margin: 2rem 0;
        }
      }
    }
  }
}
