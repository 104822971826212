@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.pcrCheckpt {
  @include interactiveComponentSpacing;
  @include fadeInAnimation;
  width: $width100;
  &__paragraph {
    @include commonWidth;
    @include flexCol;
    align-items: center;
    :nth-child(1) {
      @include intHeadText;
      @include respond-to('small') {
        width: 90%;
      }
    }
    :nth-child(2) {
      @include intCopyInstruction;
      text-align: center;
      margin-top: 2.2rem;
      @media screen and (max-width: 768px) {
        margin-top: 1.2rem;
      }
    }
  }
  &__graphic {
    width: 80%;
    margin-top: 4rem;
    position: relative;
    max-width: 560px;
    gap: 40px;
    @include flexCol;
    @include respond-to('medium') {
      flex-direction: row;
      justify-content: flex-start;
      width: 55%;
      gap: 30px;
    }
    @include respond-to('xlarge') {
      width: 39%;
      gap: 40px;
    }
    .legendGraphic {
      align-self: center;
      width: 80%;
      gap: 24px;
      z-index: 4;
      @include flexRow;
      align-items: center;
      @include respond-to('medium') {
        flex-direction: column;
        gap: 22px;
        width: 34%;
        max-width: 130.5px;
      }
      @include respond-to('xlarge') {
        gap: 30px;
      }
      img {
        width: 53%;
        height: auto;
        @include respond-to('medium') {
          width: 100%;
        }
      }
    }
    .mainGraphic {
      align-self: center;
      width: 73%;
      height: auto;
      @include respond-to('medium') {
        width: 238.54px;
        height: 251.98px;
      }
      @include respond-to('large') {
        width: 319.59px;
        height: 338.56px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  &__infoPanel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.6);
    &__wrapper {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 25px;
      width: 42%;
      height: 80vh;
      z-index: 100;
      @media screen and (max-width: 768px) {
        width: 80%;
        height: 75vh;
      }
    }

    .xButton {
      position: absolute;
      right: 1rem;
      top: -1rem;
      @media screen and (max-width: 768px) {
        right: 0rem;
        top: -1.5rem;
      }
      button {
        outline: none;
        border: none;
        background: none;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .container {
      padding: 2rem 3rem;
      overflow-y: scroll;
      height: 90%;
      @media screen and (max-width: 768px) {
        padding: 1rem 1.5rem;
      }
      &__infoBlock {
        &:not(.first) {
          margin-top: 3rem;
          @media screen and (max-width: 768px) {
            margin-top: 2rem;
          }
        }
        .header {
          @include flexRow;
          align-items: center;
          justify-content: flex-start;
          p {
            @include intCopybold;
            font-size: 16px;
            line-height: 20px;
          }

          img {
            @media screen and (max-width: 768px) {
              width: 23px;
            }
          }
          :nth-child(2) {
            color: #071332;
            margin-left: 1rem;
            font-size: 36px;
            line-height: 36px;
            @media screen and (max-width: 768px) {
              font-size: 22px;
              line-height: 26px;
            }
          }
        }
        .info {
          margin-top: 1rem;
          color: #071332;
          @include intCopyReg;
        }
      }
    }
  }
}
