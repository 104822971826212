@import "../../../styles/App.scss";

.discoveryHeader{
  min-height: 148px;
}

.top-bar {
  position: relative;
  background-color: #efefef;
  padding: toRem(15) 0;
  color: $black;
  .inner-wrapper {
    @include flexRow;
    align-items: center;
    justify-content: flex-end;
    margin: auto;
    max-width: 1308px;
    width: 90%;
   
    .live-now {
      @include flexRow;
      align-items: center;
      border-left: 10px solid #ff5314;
      gap: toRem(12);
      padding-left: toRem(20);

      a {
        color: inherit;
        text-decoration: none;
      }

      .live-now-eyebrow {
        h6 {
          @extend %font-sharp-extra;
          @include fontSize(14, 19);
          font-weight: 800;
          height: toRem(22);
          letter-spacing: toRem(0.5);
          text-transform: uppercase;
        }
      }

      .live-now-headline {
        span {
          @extend %font-sharp-extra;
          @include fontSize(20, 22);
          font-weight: 800;
          letter-spacing: toRem(0.8);
        }
      }

      &:hover {
        color: #1b7cb9;
      }
    }

    .nav-btn {
      @include flexRow;
      align-items: center;
      color: inherit;
      gap: toRem(8);
      text-decoration: none;
      .nav-btn-title {
        span {
          @extend %font-sharp-regular;
          @include fontSize(18, 27);
        }
      }

      svg {
        fill: #ff5314;
        height: toRem(22);
        width: toRem(22);
      }

      &:hover {
        color: #1b7cb9;

        svg {
          fill: #1b7cb9;
        }
      }
    }
  }
}

.discovery-header-nav {
  background-color: $white;
  padding: toRem(40) 0;
  color: $black;

  .inner-wrapper {

    @include flexRow;
    align-items: center;
    flex-wrap: wrap;
    gap: toRem(35);
    height: 100%;
    justify-content: space-between;
    margin: auto;
    max-width: 1308px;
    width: 90%;

    @include respond-to("large") {
      flex-direction: row-reverse;
      justify-content: flex-end;
      width: fit-content;
    }

    .seperator {
      order: 2;

      @include respond-to("large") {
        order: 0;
        display: none;
      }
    }

    .discovery-nav-menu-container {
      .mobile-menu-container {
        @include respond-to("large") {
          display: none;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .discovery-header-nav-menu {
        background-color: $white;
        height: 100%;
        left: 100%;
        position: fixed;
        top: 0;
        transition: all 0.5s ease;
        width: 100%;
        z-index: 9999;

        @include respond-to("large") {
          position: static;

          .mobile-menu-close {
            display: none;
          }
        }

        .mobile-menu-close {
          padding: toRem(40);
          &:hover{
            cursor: pointer;
          }
        }

        ul {
          @include flexCol;
          gap: toRem(35);
          list-style-type: none;

          @include respond-to("large") {
            flex-direction: row;
            padding: 0;
          }

          li {
            a {
              @extend %font-sharp-extra;
              @include fontSize(18, 18);
              color: inherit;
              position: relative;
              text-decoration: none;

              &::after {
                background-color: #63d6c9;
                bottom: toRem(-16);
                content: '';
                display: block;
                height: toRem(8);
                left: 0;
                position: absolute;
                transition: all 50ms ease;
                width: 0;
              }

              &:hover::after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.header--gold {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #af8d43;
  height: 32px;

  &__text {
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;
  }
}