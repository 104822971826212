@import '../../../../../styles/fonts.scss';
@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.radioLetters {
  width: 100%;
  min-height: 100vh;
  @include fadeInAnimation;
  @include interactiveComponentSpacing;
  &__headerText {
    @include flexCol;
    align-items: center;
    @include commonWidth;
    :nth-child(1) {
      @include intHeadText;
    }
    :nth-child(2) {
      @include intCopyInstruction;
      margin-top: 2rem;
      @include respond-to('medium') {
        width: 80%;
      }
    }
  }
  &__contentCont {
    width: 74%;
    margin-top: 6rem;
    max-width: 1027px;
    @include flexRow;
    @media screen and (max-width: 1024px) {
      width: 80%;
      @include flexCol;
      align-self: center;
    }

    &__left {
      background-image: url('../../../../../assets/interactive/stage1/radioLettersTubeBg.png');
      background-size: cover;
      background-repeat: none;
      background-position: center;
      width: 55%;
      @include flexCol;
      @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 1.5rem 0;
      }
      &__letters {
        width: 95%;
        align-self: center;
        text-align: center;
        overflow-wrap: break-word;
        @media screen and (max-width: 1024px) {
        }
        p {
          @extend %font-sans-serif-bold;
          word-wrap: break-word;
          font-size: 48px;
          line-height: 48px;
          text-align: center;
          color: rgba(255, 255, 255, 1);
          display: inline-block;
          @media screen and (max-width: 1024px) {
            font-size: 20px;
            line-height: 26px;
          }
        }
        p.selected {
          color: rgba(255, 255, 255, 0.1);
        }
      }
    }
    &__right {
      width: 45%;
      padding: 2rem 0;
      background: linear-gradient(
        154.34deg,
        rgba(17, 144, 235, 0.9) -3.31%,
        rgba(10, 138, 230, 0.9) 18.64%,
        rgba(79, 69, 195, 0.9) 90.32%,
        rgba(85, 39, 184, 0.9) 216.16%
      );
      @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 1rem;
      }
      &__header {
        @include flexCol;
        align-items: center;
        margin: 2rem;
        @media screen and (max-width: 1024px) {
          margin: 0.5rem;
        }
        .descrip {
          width: 95%;
          text-align: center;
          @extend %font-sans-serif-regular;
          @include fontSize(10, 14);
          @include respond-to('large') {
            @include fontSize(14, 18);
          }
        }
      }
      &__infoBlock {
        @include flexRow;
        padding: 2rem 0;

        @media screen and (max-width: 768px) {
          padding: 1rem 0;
        }
        .radioSelection {
          @include flexRow;
          align-items: center;
          justify-content: flex-start;
          width: 90%;
          & > div {
            @include flexRow;
          }
          .radio {
            @include flexRow;
            align-items: center;
            button {
              background: transparent;
              outline: none;
              border: none;
              img {
                width: 30px;
                @media screen and (max-width: 768px) {
                  width: 25px;
                }
              }
            }
            p {
              @media screen and (max-width: 768px) {
                font-size: 14px;
              }
            }
          }
        }
        .info {
          @include flexCol;
          width: 80%;
          margin-left: 1rem;
          @media screen and (max-width: 768px) {
            // width: 70%;
            margin-left: 0.5rem;
          }
          .descripTitle {
            @include intCopybold;
            font-size: 20px;
            line-height: 24px;
            @media screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
          .descrip {
            @extend %font-sans-serif-regular;
            @include fontSize(10, 14);
            @include respond-to('large') {
              @include fontSize(14, 18);
            }
          }
          .bold {
            @extend %font-sans-serif-bold;
            @include fontSize(10, 14);
            @include respond-to('large') {
              @include fontSize(14, 18);
            }
          }
        }
      }
      &__infoBlock:not(.first) {
        border-top: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
}
