@import "../../../../../styles/App.scss";

.introHero {
  overflow: hidden;
  width: 100%;
  height: 160vw;
  min-height: 512px;
  max-height: 90vh;
  min-width: 320px;
  position: relative;
  @include flexCol;
  @include respond-to("medium") {
    max-height: 50vw;
    height: 50vw;
  }
  &__bg {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    &__video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      min-height: 512px;
      &-m {
        @include respond-to("medium") {
          display: none;
        }
      }
      &-d {
        display: none;
        @include respond-to("medium") {
          display: flex;
          height: 50vw;
        }
      }
    }
  }

  &__word {
    opacity: 0;
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &__text {
      width: 86%;
      align-self: center;
      text-align: center;
      margin-top: 23%;
      @include flexCol;
      @include respond-to("medium") {
        // align-self: flex-end;
        margin-top: 8%;
        // margin-right: 14%;

        max-width: 300px;
      }
      @include respond-to("large") {
        max-width: 583px;
      }
      h1 {
        transform: scale(0.95);
        filter: blur(4px);
        animation: scale 4s forwards cubic-bezier(0.5, 1, 0.89, 1);
        @extend %font-sans-serif-semi;
        @include fontSize(72, 74);
        margin-bottom: 20px;
        @include respond-to("medium") {
          @include fontSize(48, 48);
          margin-bottom: 8px;
        }
        @include respond-to("xlarge") {
          @include fontSize(86, 90);
          margin-bottom: 16px;
        }
      }
      p {
        opacity: 0;
        @extend %font-sans-serif-medium;
        font-size: 16px;
        line-height: 24px;
        align-self: center;
        @include respond-to("medium") {
          max-width: 300px;
          font-size: 13px;
          line-height: 20px;
        }
        @include respond-to("xlarge") {
          max-width: 550px;
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
    &__scroll {
      align-self: center;
      position: absolute;
      z-index: 2;
      bottom: min(10%, 80px);
      @include flexCol;
      span {
        font-size: 12px;
        text-align: center;
        margin-bottom: 16px;
        color: #ffffff;
        @extend %font-sans-serif-regular;

        @include respond-to("xlarge") {
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 20px;
        }
      }
      img {
        @include scrollIcon;
      }
    }
  }
}

@keyframes scale {
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    filter: blur(0);
    -moz-filter: blur(0);
    -webkit-filter: blur(0);
    -o-filter: blur(0);
  }
}

