@import "../../../../styles/App.scss";

.progress {
  align-self: center;
  @include flexRow;
  justify-content: space-between;
  &-intro {
    width: calc(63% + 30px);
    padding: 20px 0;
    @include respond-to("medium") {
      width: calc(60vw + 120px);
      padding: 30px 0;
    }
    @include respond-to("large") {
      max-width: 932px;
      width: calc(60vw + 152px);
      padding: 40px 0;
    }
  }
  &-stage {
    width: 70vw;
    max-width: 255px;
    padding-top: 8.5vh;
    margin-bottom: 16px;
    @include respond-to("small") {
      width: 50vw;
      max-width: 374px;
      padding-top: 60px;
    }
    @include respond-to("large") {
      margin-bottom: 30px;
      max-width: 678px;
    }
    // @include respond-to("xlarge") {
    //   margin-top: 100px;
  
    // }
  }
  &__number {
    
    position: relative;
    &-intro {
      opacity: 0;
    }
    @include flexRow;
    &__circle {
      width: 40px;
      height: 40px;
      @include respond-to("medium") {
        font-size: 30px;
        line-height: 30px;
      }
      @include respond-to("large") {
        width: 62px;
        height: 62px;
      }
      &-stage {
        width: 24px;
        height: 24px;
        @include respond-to("medium") {
          width: 48px;
          height: 48px;
        }
      }
      &__svg{
        width: 100%;
        height: 100%;
      }
    }

    &__text {
      @extend %font-sans-serif-medium;
      font-size: 16px;
      line-height: 16px;
      align-self: center;
      position: absolute;
      color: $colorPrimary;
      @include respond-to("medium") {
        font-size: 20px;
        line-height: 20px;
      } @include respond-to("large") {
        font-size: 30px;
        line-height: 30px;
      }

      &-stage {
        font-size: 12px;
        line-height: 12px;
        @include respond-to("medium") {
          font-size: 24px;
          line-height: 24px;
        }
      }
      &--active {
        color: #071e42;
      }
    }
  }
  &__bar {
    // &-intro {
    //   opacity: 0;
    //   width: 0px;
    // }
    align-self: center;
    width: calc(100% / 3);
    height: fit-content;
    position: relative;
    &-base {
      min-height: 1px;
      height: 1px;
      width: 100%;
      background: $colorPrimary;
      &-intro {
        opacity: 0;
        width: 0px;
      }
      &-intro-top {
          opacity: 0;
          width: 0px;
      }
    }
    &-progress {
      position: absolute;
      z-index: 2;
      margin-top: -1.5px;
      height: 3px;
      width: 100%;
      background: $colorPrimary;
      @include respond-to("medium") {
        margin-top: -2.5px;
        height: 5px;
      }
      
    }
    &-animate {
      position: absolute;
      z-index: 2;
      margin-top: -1.5px;
      height: 3px;
      width: 0%;
      background: $colorPrimary;
      @include respond-to("medium") {
        height: 5px;
        margin-top: -2.5px;
      }
    }
  }
}
