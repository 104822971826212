@import "../../../styles/App.scss";

.subPgsNav {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 16px 0 24px 0;
  background: linear-gradient(180deg, rgba(2, 24, 32, 0.5) 35.22%, rgba(0, 156, 222, 0.5) 114.03%), #021820;
  @include flexCol;
  @include respond-to("medium") {
    padding: 0;
    height: 120px;
    flex-direction: row;
    justify-content: center;
  }
  @include respond-to("large") {
    height: 160px;
  }
&--bg {
  background: rgba(0, 0, 0, 0);
}

  &__links {
    align-self: center;
    display: flex;
    justify-content: space-between;
    @include maxWidth;
    &__link {
      @extend %font-sans-serif-regular;
      @include fontSize(10, 12);
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        color: $colorPrimary;
      }
      @include respond-to("medium") {
        @include fontSize(12, 15);
      }
      @include respond-to("large") {
        @include fontSize(16, 23);
      }
    
      &--hide {
        visibility: hidden;
      }
    }
   
  }
  &__logo {
    align-self: center;
    width: 126px;
    margin-top: 20px;
    @include respond-to("medium") {
      margin-top: 0;
      width: 172px;
      position: absolute;
    }
    @include respond-to("large") {
      width: 214px;
    }
  }
}
