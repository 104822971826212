@import "../../../../../styles/fonts.scss";
@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.linesAnimation {
  @include commonWidth;
  @include interactiveComponentSpacing;
  @include intSpacingBottom;
  height: fit-content;
  &__paragraph {
    @include intCopyReg;
    width: 100%;
    p {
      @include textAnimation;
    }
  }
  &__lottie {
    width: 100%;
    height: auto;
    margin-top: 4rem;
  }
  :nth-child(3) {
    margin-top: 5.1rem;
  }
}
