@font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/Lora-VariableFont_wght.ttf");
}

@font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 400;
    src: url("../assets/fonts/Lora-Italic-VariableFont_wght.ttf");
}

@font-face {
    font-family: 'sequelSans';
    src: url("../assets/fonts/SequelSans-Regular.ttf");
}
  
@font-face {
    font-family: 'sequelSans';
    font-weight: bold;
    src: url("../assets/fonts/SequelSans-Bold.ttf");
}

@font-face {
    font-family: 'sequelSans';
    font-weight: lighter;
    src: url("../assets/fonts/SequelSans-Light.ttf");
}

@font-face {
    font-family: 'sequelSans';
    font-weight: 600;
    src: url("../assets/fonts/SequelSans-Semi.ttf");
}

@font-face {
    font-family: 'sequelSans';
    font-weight: 500;
    src: url("../assets/fonts/SequelSans-Medium.ttf");
}

@font-face {
    font-family: 'sequelSans Book';
    src: url("../assets/fonts/SequelSans-Book.ttf");
}


@font-face {
    font-family: 'Sharp Sans Extrabold';
    font-weight: 900;
    src: url("../assets/fonts/SharpSans-Extrabold.otf");
}

@font-face {
    font-family: 'Sharp Sans Book';
    font-weight: 400;
    src: url("../assets/fonts/SharpSans-Book.otf");
}

@font-face {
    font-family: 'Trade Gothic LT Std Bold Condensed';
    font-weight: 400;
    src: url("../assets/fonts/Trade-Gothic-LT-Std-Bold-Condensed.woff2");
}




%font-serif-regular {
    font-family: 'Lora', serif;
    font-style: normal;
    font-weight: 400;
}

%font-serif-regular-italic {
    font-family: 'Lora', serif;
    font-style: italic;
    font-weight: 400;
}

%font-sans-serif-regular {
    font-family: 'sequelSans', sans-serif;
    font-style: normal;
    font-weight: 400;
}

%font-sans-serif-medium {
    font-family: 'sequelSans', sans-serif;
    font-style: normal;
    font-weight: 500;
}

%font-sans-serif-book {
    font-family: 'sequelSans Book', sans-serif;
    font-style: normal;
}

%font-sans-serif-bold {
    font-family: 'sequelSans', sans-serif;
    font-style: normal;
    font-weight: bold;
}

%font-sans-serif-light {
    font-family: 'sequelSans', sans-serif;
    font-style: normal;
    font-weight: lighter;
}

%font-sans-serif-semi {
    font-family: 'sequelSans', sans-serif;
    font-style: normal;
    font-weight: 600;
}

%font-sharp-extra {
    font-family: 'Sharp Sans Extrabold', Arial, sans-serif;
    font-style: normal;
    font-weight: 900;
}

%font-sharp-regular {
    font-family: 'Sharp Sans Book', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
}