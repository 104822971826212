@import "../../../styles/App.scss";

.nav {
    @include flexRow;
    background: linear-gradient(180deg, #000000 -116.85%, rgba(0, 0, 0, 0) 100%);
    box-sizing: border-box;
    width: 100%;
    min-width: 320px;
    color: $white;
    position: absolute;
    top: 0;
    padding: toRem(12) toRem(16);
    z-index: 999;
    justify-content: space-between;

    @include respond-to("medium") {
        padding: toRem(20);
        height: auto;
    }

    @include respond-to("large") {
        padding: toRem(30);
        height: auto;
    }

    &__logo {
        align-self: center;
        width: toRem(107);

        @include respond-to("medium") {
            width: toRem(85);
        }

        @include respond-to("large") {
            width: toRem(161);
        }

        &-img {
            width: 100%;
            height: 100%;
        }
    }

    &__links {
        @include flexRow;
        align-items: center;
        column-gap: toRem(47);
    }

    &__item {
        @include flexCol;
        white-space: nowrap;
        align-self: flex-end;
        &__link {
            align-self: center;
            @extend %font-sans-serif-medium;
            @include fontSize(14);
            display: inline-block;
            text-decoration: none;
            color: $white;
            text-transform: uppercase;

            &:hover {
                color: $colorPrimary;
            }
            &--cs {
                align-self: center;
                @extend %font-sans-serif-light;
                font-size: 11px;
                line-height: 13px;
            }
        }
    }

    &__menu {
        background: none;
        border: none;
        align-self: center;

        &:hover {
            cursor: pointer;
        }

        &__icon {
            width: toRem(22);
            height: toRem(12);
            fill: $white;
        }
    }
}
