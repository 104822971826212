@import "../../../../styles/App.scss";

.card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
  @include flexCol;
  @include respond-to("medium") {
    padding: min(20px, 2vw);
    margin-bottom: 0;
  }
  &--hide {
    opacity: 0;
  }
  &--incomplete {
    background: rgba(51, 119, 164, 0.1);
    border: 1px solid #3377a4;
    border-radius: 3.62013px;
    @include respond-to("medium") {
      border: 1.5px solid #3377a4;
      border-radius: 6px;
    }
    @include respond-to("large") {
      border: 3px solid #3377a4;
      border-radius: 10px;
    }
  }
  &-intro {
    width: 40vw;
    opacity: 0;
    height: calc(40vw * 1.35);
    max-width: 260px;
    max-height: 324.5px;
    justify-content: flex-start;
    @include respond-to("medium") {
      width: 20vw;
      height: calc(20vw * 1.25);
    }
  }
  &-stage {
    width: 19vw;
    height: calc(19vw * 1.1);
    max-width: 70px;
    max-height: 77px;
    border-radius: 3.62013px;
    @include respond-to("small") {
      width: 15vw;
      height: calc(15vw * 1.1);
      max-width: 112px;
      max-height: 123.2px;
    }
    @include respond-to("medium") {
     
      border-radius: 6px;
    }
   
    @include respond-to("large") {
      max-width: 200px;
      max-height: 220px;
      border-radius: 10px;
    }
  }

  &-0 {
    background: url("../../../../assets/interactive/stage1/stage1_bg.png"), #ffffff;
    background-position: 35% 10%;
    background-size: cover;
  }
  &-1 {
    background: url("../../../../assets/interactive/stage2/stage2_bg.jpg"), #ffffff;
    background-position: 25% 60%;
    background-size: cover;
  }
  &-2 {
    background: url("../../../../assets/interactive/stage3/stage3_bg.jpeg"), #ffffff;
    background-size: cover;
  }
  &-3 {
    background: url("../../../../assets/interactive/stage4/stage4_bg.jpeg"), #ffffff;
    background-size: cover;
  }
  &__icon {
    align-self: center;
    @include flexRow;
    &-intro {
      height: calc(40vw * 0.6);
      margin-bottom: 8px;
      @include respond-to("medium") {
        height: calc(20vw * 0.45);
        max-height: 156px;
      }
      @include respond-to("large") {
        height: calc(20vw * 0.6);
      }
    }
    &-stage {
      max-width: calc(19vw * 0.5);
      max-height: 50px;
      @include respond-to("small") {
        max-width: calc(15vw * 0.5);
        max-height: 78px;
      }
      @include respond-to("large") {
        max-height: 142px;
      }
    }
   

    &-icon {
      align-self: center;
      &-intro {
        width: 65%;
        height: auto;
      }
      &-stage {
        height: 100%;
        width: auto;
      }
    }
  }
  &__text {
    text-align: center;
    @include flexCol;
    justify-content: flex-start;
    &__stage {
      @include flexRow;
      align-self: center;
      @extend %font-sans-serif-semi;
      padding: 3px 7.5px;
      text-transform: uppercase;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      font-size: 7px;

      @include respond-to("large") {
        font-size: 8px;
        padding: 3.5px 12.5px;
      }
    }
    h4 {
      @extend %font-sans-serif-semi;
      font-size: 18px;
      line-height: 21px;
      margin: 4px 0;
      white-space: nowrap;
      @include respond-to("large") {
        font-size: 24px;
        line-height: 27px;
      }
      @include respond-to("xlarge") {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 8px;
      }
    }
    p {
      @extend %font-sans-serif-medium;
      font-size: 10px;
      line-height: 13px;
      color: rgba(255, 255, 255, 0.8);

      @include respond-to("large") {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}

@keyframes changeOpacity {
  0%{

  }
  100%{

  }
}