@import "../../../styles/App.scss";

.hero {
  background-color: #021820;
  overflow: hidden;
  position: relative;
  padding-bottom: toRem(65);

  @include respond-to("medium") {
  
    padding-bottom: toRem(85);
  }

  @include respond-to("large") {
  
    padding-bottom: toRem(140);
  }
  @include flexCol;
  &__video {
    position: absolute;
    object-fit: cover;
    top: 0;
    width: 100%;
    height: 115.67vw;
    @include respond-to("medium") {
      height: 45.85vw;
    }
    &-d {
      display: none;
      @include respond-to("medium") {
       display: block;
      }
    }
    &-m{
      @include respond-to("medium") {
        display: none;
       }
    }
  }
  .video-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 115.67vw;
    z-index: 1;
    background: linear-gradient(0.21deg, #021820 7.78%, rgba(2, 24, 32, 0) 68.09%);
    @include respond-to("small") {
      display: none;
    }
  }
  &::after,
  &::before {
    content: "";
    height: 50%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &::before {
    background: linear-gradient(0.21deg, #021820 7.78%, rgba(2, 24, 32, 0) 68.09%);
    top: 0;
    @include respond-to("medium") {
      height: 30%;
    }

    @include respond-to("2xlarge") {
      height: 50%;
    }
  }

  &::after {
    background: linear-gradient(175.31deg, rgba(2, 24, 32, 0.5) 35.22%, rgba(0, 156, 222, 0.5) 182.39%), #021820;
    bottom: 0;

    @include respond-to("medium") {
      height: 70%;
    }

    @include respond-to("2xlarge") {
      height: 50%;
    }
  }

  .hero-lockup {
    margin: 0 auto toRem(160);
    max-width: 100%;
    position: relative;
    z-index: 99;

    @include respond-to("medium") {
      max-width: 65%;
    }
  }

  .genomes-left,
  .genomes-right {
    align-items: center;
    column-gap: toRem(8);
    display: flex;
    position: absolute;
  }

  .genomes-left {
    left: -10%;
    top: -50%;

    @include respond-to("medium") {
      left: 0;
      top: -50%;
    }
  }

  .genomes-right {
    right: -10%;
    top: 60%;

    @include respond-to("medium") {
      right: 0;
      top: 50%;
    }
  }

  .inner-wrap {
    padding: toRem(200) 5% toRem(65);
    padding-bottom: 0;

    @include respond-to("medium") {
      padding: toRem(200) 0 toRem(85);
      padding-bottom: 0;
    }

    @include respond-to("large") {
      padding: toRem(300) 0 toRem(140);
      padding-bottom: 0;
    }
  }

  .hero-content {
    position: relative;
    z-index: 10;

    @include respond-to("medium") {
      @include maxWidth;
      gap: toRem(160);
      padding: 0 calc(cols(2) + 5%);
    }

    .hero-description {
      p {
        @extend %font-serif-regular;
        @include fontSize(18, 24);
        padding-bottom: toRem(40);

        @include respond-to("medium") {
          @include fontSize(18, 30);
        }

        @include respond-to("large") {
          @include fontSize(30, 48);
        }
      }
    }
  }

  .hero-map {

    align-self: center;
    position: relative;
    z-index: 9;
    width: 109.2vw;
    max-width: 1171px;
    height: auto;
    margin-top: -10vw;
    margin-left:20%;
    @include flexRow;
    @include respond-to("medium") {
      // padding: 0 5%;
      // margin-top: -10vw;
       margin-left: min(98.5px, 6.84%);
      width: 98.43vw;
    }

    @include respond-to("large") {
      // padding: 0 calc(cols(1, 14) + 5%);
      // margin-top: -10vw;
      width: 81.32vw;
    }
    .map-markers {
      // left: 50%;
      position: absolute;
      // top: 0;
      // transform: translateX(-50%);
      width: 100%;
      svg {
        .st0 {
          @extend %font-sans-serif-regular;
        }

        .st1 {
          @extend %font-sans-serif-bold;
        }
      }

      svg,
      img {
        height: auto;
        margin: auto;
        width: 100%;
      }
    }
    // .hero-map-container {
    //   position: relative;

    //   .map-markers {
    //     // left: 50%;
    //     position: absolute;
    //     // top: 0;
    //     // transform: translateX(-50%);
    //     // width: 100%;
    //   }

    // }
  }
}
