@import "./variables.scss";
@import "./mixins.scss";
@import "./fonts.scss";
@import "./animations.scss";

html {
  scroll-behavior: smooth;
}

.App {
  @include flexCol;
  width: 100%;
  min-width: 320px;
  color: $white;
  overflow: hidden;
  position: relative;
}

.southAfrica,
.india,
.unitedStates,
.colombia,
.macro {
  background: linear-gradient(210.89deg, #021820 63.81%, #009CDE 155.17%);;
}

.india .SubPgsContent-eyebrow span,
.india .SubPgsQuote__author span {
  color: $green;
}

.india .SubPgsContent-bio-description::after,
.india .subPgsHeroes__title::after {
  background: $green;
}

.southAfrica .SubPgsContent-eyebrow span,
.southAfrica .SubPgsQuote__author span {
  color: $orange;
}

.southAfrica .SubPgsContent-bio-description::after,
.southAfrica .subPgsHeroes__title::after {
  background: $orange;
}

.unitedStates .SubPgsContent-eyebrow span,
.unitedStates .SubPgsQuote__author span {
  color: $yellow;
}

.unitedStates .SubPgsContent-bio-description::after,
.unitedStates .subPgsHeroes__title::after {
  background: $yellow;
}

.colombia .SubPgsContent-eyebrow span,
.colombia .SubPgsQuote__author span {
  color: $purple;
}

.colombia .SubPgsContent-bio-description::after,
.colombia .subPgsHeroes__title::after {
  background: $purple;
}


*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

svg .st0 {
  @extend %font-sans-serif-regular;
}

svg .st1 {
  @extend %font-sans-serif-bold;
}

.eyebrow {
  @extend %font-sans-serif-bold;
  @include fontSize(14, 16);
  text-transform: uppercase;
  padding-bottom: toRem(4);
  @include respond-to("medium") {
    @include fontSize(10, 10);
  }

  @include respond-to("large") {
    @include fontSize(20, 20);
    padding-bottom: toRem(10);
  }
}

.interactive__upperText {
  align-self: center;
  position: relative;
  &__copy {
      position: absolute;
      top: 0;
    }
}