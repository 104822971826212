@import "../../../../../styles/App.scss";

.sequence3 {
    align-self: center;
    width: 100%;
    position: relative;
    @include flexCol;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    height: 450vh;

    &-wrapper {
        @include flexCol;
        position: relative;
        justify-content: flex-start;
        width: 100%;
    }

    &__copy {
        align-self: center;
        margin-top: 60px;
        @include commonWidth;
        @include intCopyReg;
        @extend .interactive__upperText;
        height: 180px;
        @include respond-to("small") {
            height: 150px;
        }
        @include respond-to("large") {
            height: 180px;
        }

        &-lower {
            height: fit-content;
            margin-top: 0;
        }
        &__text {
            @include textAnimationGsap;
            @extend .interactive__upperText__copy;
        }
    }
    &__animation {
        align-self: center;
        position: relative;
        align-items: center;
        z-index: 4;
        @include flexCol;
        &__lottie {
            width: 100%;
            height: 100%;
            @include respond-to("small") {
                width: 100%;
                height: auto;
            }
        }
        &-container {
            align-self: center;
            width: auto;
            height: calc(100vh - 240px);
            @include flexRow;
            overflow: hidden;
            @include respond-to("small") {
                width: 80vw;
                height: auto;
                max-width: 1000px;
            }
        }
        &-test {
            position: absolute;
            top: 0;
            @include respond-to("small") {
                top: -60px;
            }
        }
        &-tube {
            opacity: 0;
            @include respond-to("small") {
                margin-top: 60px;
            }
        }
    }
}
