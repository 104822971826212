@import "../../../styles/App.scss";

.onTheHunt {
  background: url("../../../assets/interactiveCardBgM.jpg") top center/100% auto no-repeat;
  position: relative;

  @include respond-to("medium") {
    @include background;
    background-image: url("../../../assets/interactiveCardBg.jpg");
    height: 56.25vw;
  }

  &::after {
    background-color: #009cde;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    transition: all 0.5s ease;
    top: 0;
    width: 100%;
  }

  &::after {
    background-color: #009cde;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    transition: all 0.5s ease;
    top: 0;
    width: 100%;
  }

  &::before {
    @include respond-to("medium") {
      background-image: url("../../../assets/episode/article_gradient.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 0 0 64px 0;
      content: "";
      height: 100%;
      right: 0;
      position: absolute;
      top: 0;
      width: calc(5% + cols(5));
    }

    @include respond-to("large") {
      border-radius: 0 0 0 120px;
      width: calc(5% + cols(5, 14));
    }
  }

  &.animate-cover::after {
    height: toRem(4);
  }

  .inner-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    position: relative;
    justify-content: flex-end;
    @include respond-to("medium") {
      @include maxWidth;
    }
    @include respond-to("large") {
      width: 92%;
    }
    @include respond-to("xlarge") {
      width: 90%;
    }

    .article-content {
      background-image: url("../../../assets/episode/article_gradient_mobile.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 0 120px 0 0;
      margin-top: 100vw;
      padding: toRem(60) 5%;

      @include respond-to("medium") {
        background: transparent;
        border-radius: 0;
        flex: 0 0 cols(4);
        margin-top: 0;
        padding: toRem(100) 0;
        margin-right: cols(0.8);
      }
      @include respond-to("large") {
        flex: 0 0 cols(4, 14);
        margin-right:calc(10% - 80px);
        padding: 0;
      }
      @include respond-to("xlarge") {
        margin-right: cols(0.8, 14);
      }

      .article-eyebrow {
        padding-bottom: toRem(14);

        @include respond-to("medium") {
          padding-bottom: toRem(10);
        }

        @include respond-to("large") {
          padding-bottom: toRem(23);
        }

        span {
          @extend %font-sans-serif-light;
          @include fontSize(11);
          text-transform: uppercase;

          @include respond-to("large") {
            @include fontSize(16);
          }
        }
      }

      .article-title {
        h2 {
          white-space: nowrap;
          @include tileTitleSmall;
        }
      }

      .article-description {
        p {
          @include tileCopySmall;
        }
      }
    }
  }
}
