@import "../../styles/App.scss";

h1.head {
  font-size: 38.2256px;
  line-height: 38px;
  text-align: center;
  margin: 0;
  @extend %font-sans-serif-semi;
  @include respond-to("medium") {
    font-size: 60px;
    line-height: 60px;
  }
}

h2.subhead {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  @extend %font-sans-serif-medium;
  @include respond-to("medium") {
    font-size: 16px;
    line-height: 24px;
  }
}

a.qaHero__content__nav__link {
  font-size: 10px;
  line-height: 12px;
  color: $white;
  @extend %font-sans-serif-regular;
  @include respond-to("medium") {
    font-size: 12px;
    line-height: 15px;
  }
    @include respond-to("large") {
    font-size: 16px;
    line-height: 23px;
  }
}

p.paragraph {
  font-size: 18px;
  line-height: 27px;
  margin: 18px 0;
  @extend %font-serif-regular;
  @include respond-to("medium") {
    font-size: 18px;
    line-height: 27px;
  }
}

.qaArticle {
  min-width: 320px;
  width: $width100;
  position: relative;
  @include flexCol;
  &__nav {
    width: 100%;
    align-self: center;
    position: relative;
    @include flexCol;
  }
  &__cta {
    width: 92%;
    color: $black;
    max-width: 832px;
    align-self: center;
    border-top: 1px solid $lines;
    margin: 20px 0;
    padding: 20px 0;
    font-size: 15px;
    line-height: 20px;
    @extend %font-sans-serif-medium;
    @include respond-to("medium") {
      margin: 40px 0;
      padding: 36px 0;
      font-size: 18px;
      line-height: 24px;
    }
    &__hyperlink {
      align-self: center;
      text-decoration: none;
      color: inherit;
      display: flex;
      justify-content: flex-start;
      @extend %font-sans-serif-bold;
      &:hover {
        color: $colorPrimary;
      }
      &__arrow {
        align-self: center;
        margin-top: 2px;
        margin-left: 10px;
      }
      &:hover .qaArticle__cta__hyperlink__arrow__path {
        fill: $colorPrimary;
      }
    }
  }
}

.gavin {
  background-color: $plum;
}
.alan {
  background-color: $fuchsia;
}
.pontiano {
  background-color: $red;
}
.dora {
  background-color: $orange-qa;
}
.tulio {
  background-color: $yellow-qa;
}
.melody {
  background-color: $green;
}
.jorge {
  background-color: $ltGreen;
}
.mary {
  background-color: $blue;
}
