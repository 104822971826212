@import "../../../styles/App.scss";

.inline-photo {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: toRem(267);

  @include respond-to("medium") {
    height: toRem(540);
  }

  @include respond-to("large") {
    height: toRem(500);
  }
}