@import '../../../../styles/App.scss';

.mapDots {
  position: absolute;
  top: 0;
  align-self: center;
  @include commonWidth;
  opacity: 0;
  @include flexCol;
  justify-content: flex-start;
  &-1 {
    z-index: 10;
  }
  &__mapCopy {
    width: 100%;
    margin-top: 60px;
    @include respond-to('medium') {
      height: 160px;
    }
    @include respond-to('large') {
      height: 200px;
    }
    @extend .interactive__upperText;
    &-0 {
      height: 150px;
      @include respond-to('medium') {
        height: 160px;
      }
      @include respond-to('large') {
        height: 200px;
      }
    }
    &-1 {
      height: 30px;
      @include respond-to('medium') {
        height: 20px;
      }
    }

    &__copy {
      @extend .interactive__upperText__copy;
    }
    &-lower {
      margin-top: 0;
    }
  }
  &__copy {
    // margin-top: 2rem;
    opacity: 0;
    transform: translateY(50px);
    &--b {
      // margin-top: 0;
      @include intCopybold;
    }
    &--r {
      // margin-top: 0;
      @include intCopyReg;
    }
    &-lower {
      margin-top: 0;
    }
    &__text {
      opacity: 0;
      transform: translateY(50px);
      @extend .interactive__upperText__copy;
      &__popup {
        @include popupButton;
      }
    }
  }

  &__lottie {
    width: 100%;
    height: auto;
    align-self: center;
    margin: 2.5rem 0;
    @include respond-to('xlarge') {
      margin: 5rem 0;
    }
  }
}
