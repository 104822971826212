@import "../../../styles/App.scss";

.questions {
    align-self: center;
    width: 100%;
    border-bottom: 1px solid $lines;
    @include flexCol;
    & h3 {
        align-self: center;
        max-width: 620px;
        text-align: center;
        color: $black;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 25px;
        margin-top: 10px;
        @extend %font-sans-serif-bold;
        @include respond-to("medium") {
            width: 100%;
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 40px;
        }
    }
}
