@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/variables.scss';

.paragraphWLottie2 {
  @include fadeInAnimation;
  @include flexCol;
  align-items: center;
  @include commonWidth;
  @include intSpacingBottom;
  &__textCont {
    p {
      @include intCopyReg;
      margin-top: 2rem;
    }
    :nth-child(2) {
      @include intCopybold;
    }
  }
  &__lottieCont {
    width: 100%;
    margin-top: 5rem;

    @media screen and (max-width: 768px) {
      margin-top: 4rem;
    }
  }
}
