@import "../../styles/App.scss";

.macro {
    @include flexCol;
}
.macroBody {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s ease;
    width: 85vw;
    max-width: 620px;
    align-self: center;
    padding: 40px 0;
    @include respond-to("large") {
        padding: 80px 0;
      }
    @include flexCol;
    &__eyebrow{
        margin: 0;
        color: $colorPrimary;
        @extend %font-sans-serif-bold;
        @include fontSize(14, 16);
        text-transform: uppercase;
        @include respond-to("large") {
          @include fontSize(24, 50);
        }
    }
    &__title {
        margin: 0;
        white-space: nowrap;
        @extend %font-sans-serif-bold;
        @include fontSize(30, 36);
        text-transform: uppercase;
        margin-bottom: toRem(12);

        @include respond-to("large") {
            margin-bottom: toRem(16);
          @include fontSize(48, 50);
        }
      
    }
    &__description {
        margin: 0;
        margin-bottom: toRem(40);
        @extend %font-serif-regular;
        @include fontSize(16, 24);
        @include respond-to("large") {
          @include fontSize(20, 30);
         
       
        }
    }
    &__back {
        color: $colorPrimary;
        text-transform: uppercase;
        text-decoration: none;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        @extend %font-sans-serif-regular;
        @include fontSize(12, 16);
        @include respond-to("large") {
          @include fontSize(16, 19);
         
       
        }
    }

}